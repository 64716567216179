
// MERCH TIER
///////////////////////////////////////////////////////////
.merchtier {
    @include tier-fullbleed($bg: $color-grey0);

    @include media-query(phone) {
        @include multiple-props(padding-left padding-right, 0);
    }

    &__title {
        margin-bottom: space(2);
        text-align: center;
    }

    &__merchs {
        @include flex($wrap: wrap, $justify: center);
    }
}
