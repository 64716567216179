
// FORMS (holders for controls)
///////////////////////////////////////////////////////////
.form {
    $form-space: space(1);

    @include cols(4, $cols-tablet: 6, $cols-phone: 12);
    @include position(relative, $z-layer: arbitrary);
    margin: 0 auto 0;
    padding-bottom: space(6);

    &__row {
        @include flex();
        margin-left: calc(-1 * #{$form-space});

        &--header {
            @extend .text--mono-large;
            display: block;
            text-align: center;

            .form__cell {
                margin-right: $form-space;
            }
        }

        &--left-header {
            @extend .text--mono-large;
            margin-bottom: space(2);
        }

        &--left-subheader {
            @extend .text--body;
            margin-bottom: space(2);
        }

        &--small {
            margin-top: space(1);
        }

        &--text {
            display: block;
            margin-left: 0;
            margin-top: space(1);
            text-align: center;

            .form__cell {
                margin-left: auto;
                margin-right: auto;
                max-width: 30em;
            }
        }

        &--ctas {
            margin-top: $controls-field-label-minheight;
        }
        &--text + &--ctas {
            margin-top: space(1);
        }
    }

    &__cell {
        flex: 1;
        margin-left: $form-space;

        &--touching {
            margin-left: 0;
        }

        &--space-below {
            margin-bottom: space(1);
        }

        .radioset {
            margin-bottom: space(1);
        }

        .button {
            width: 100%;
        }
    }
    
    // text
    &__bodytext {
        @extend .text--body-small;
        max-width: 30em;

        &--big {
            @extend .text--body;
        }
    }

    &__error {
        @extend .text--color-bad;
    }

    &__msg {
        @extend .text--color-good;
    }

    &__sep {
        margin: 0 space(0.25);

        &::after {
            color: $color-grey1;
            content: ' | ';
        }
    }
}
