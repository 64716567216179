
// AUCTION
// (auction detail page)
///////////////////////////////////////////////////////////
$auction-hero-border-width: space(0.5);

.auction {
    @include position(relative);
    @include underlink-set-color($color-black);

    &__hero {
        @include flex($dir: column, $justify: flex-end);
        border: $auction-hero-border-width solid $color-white;
        border-bottom-color: transparent;
        height: 100vh;
        margin-bottom: space(2);
        margin-left: calc(-1 * var(--col-gutter));
        margin-right: calc(-1 * (var(--col-gutter) - var(--scrollbar-width)));
        margin-top: calc(-1 * (var(--headers-height) + var(--view-toppad)));
        padding: calc(var(--col-gutter) * 0.75);
        position: relative;
        
        @include media-query(ratio-goldenratio-and-below) {
            height: 100vw * 50 / 81;
        }
        @include media-query(phone) {
            margin-bottom: 0;
        }

        &--is-dark-media {
            color: $color-white;
        }

        &--has-partner {
            margin-bottom: space(1);
        }
        
        // if there's no regular hero on the auction, we show the 
        // first sneak peek img just for phones
        &--sneak-peek-media {
            @include show-on(phone);
        }

        .media {
            @include position(absolute, top bottom left right, 0);
        }
        
        // if the screen is below a 4/3 ratio (or is a phone), 
        // the title here will be hidden and will be shown in
        // .auction__titlerow instead
        &__title {
            max-width: rem(500);
            position: relative;

            @include media-query(ratio-fourthirds-and-below) {
                display: none;
            }
            @include media-query(phone) {
                display: none;
            }
        }
    }

    &__cols {
        @include flex($align: flex-start);
        @include media-query(phone) {
            flex-wrap: wrap;
        }
    }
    &__col {
        &--info {
            @include cols(4, $cols-tablet: 6, $cols-phone: 12);
            @include flex($dir: column);
            @include tabular-set-value(width, $tabular-width-of-longest-date);
            @include stickyscroller-target($top: space(3));
            @include responsive(padding-right, (
                default: space(3),
                phone: 0
            ));
            padding-bottom: space(3);

            h1 {
                @include responsive(margin-bottom, (
                    default: space(1),
                    phone: space(0.5)
                ));
            }
        }
        &--lots {
            @include flex($wrap: wrap);
            flex: 1;
            margin-top: calc(-1 * var(--lotblock-toppad));
        }
    }

    &__titlerow {
        @include flex($dir: column, $justify: space-between);
        min-height: calc(var(--lotblock-height) - var(--lotblock-toppad));
        padding-bottom: space(2);

        @mixin titlerow-phone-position-when-hero-visible() {
            @include media-query(phone) {
                @include position(relative, $z-layer: arbitrary);
                margin-top: calc(#{-1 * $auction-hero-border-width} - #{$tag-vert-pad} - #{$tag-font-size / 2});
                min-height: auto;
            }
        }
        
        &--has-hero {
            @include titlerow-phone-position-when-hero-visible();
            @include media-query(not-phone-ratio-fourthirds-and-above) {
                @include responsive(padding-top, (
                    default: space(2),
                    phone: 0
                ));
                min-height: auto;
                top: 0;

                .tag,
                h1 {
                    display: none;
                }
            }
        }

        &--has-sneak-peek-media {
            @include titlerow-phone-position-when-hero-visible();
        }

        &--has-partner {
            padding-top: 0;

            h1 {
                margin-bottom: space(0.5);
            }
        }

        @include media-query(phone) {
            min-height: auto;
        }

        &__description {
            @extend .text--body-big;
            max-width: 18em;
        }

        &__partner {
            @extend .text--mono-small;
            @include responsive(margin-bottom, (
                default: space(3),
                phone: space(2)
            ));
            line-height: $lineheight-tight;
            max-width: 12em;
        }
        &--has-hero #{&}__partner {
            margin-bottom: space(2);
        }
    }

    &__inforow {
        @include borders(top);
        padding-bottom: space(1);
        padding-top: space(1);

        &__lockup {
            @include flex($align: flex-start);
            margin-top: space(1);
        }

        &__text {
            @extend .text--body;
            flex: 1;
            padding-right: space(2);

            @include media-query(phone-tiny) {
                font-size: rem(13);
                padding-right: space(1.25);
            }
        }

        &__media {
            padding-top: rem(4);
            width: $tabular-width-of-longest-date;
        }

        @include media-query(phone-tiny) {
            &__lockup--charity &__media {
                width: col-width(3);
            }
        }
    }

    &__lot {
        @include flex($align: flex-end);
        @include lotblock-parent-border();
        position: relative;

        @include media-query(full) {
            @include lotblock-parent-no-border-for-first-n(2);
            width: 50%;

            &:nth-child(2n + 1) {
                @include lotblock-parallax(center);
            }
            &:nth-child(2n + 2) {
                @include lotblock-parallax(right);
            }
        }

        @include media-query(mobile) {
            @include lotblock-parent-no-border-for-first-n(1);
            width: 100%;
        }

        @include media-query(tablet) {
            --lotblock-rightpad: #{space(1)};

            &:nth-child(1n + 1) {
                @include lotblock-parallax(right);
            }
        }

        @include media-query(phone) {
            &:nth-child(1n + 1) {
                @include lotblock-parallax(center, $multiplier: 3);
            }
        }

        &--only {
            width: 100%;
        }

        &--sneakpeek {
            @include lotblock-parent-no-border();
            align-items: center;
        }

        .lotblock {
            width: 100%;
        }

        &__sneakpeekblock {
            @include position(relative, $z-layer: arbitrary);
            padding: var(--lotblock-toppad) var(--lotblock-rightpad) var(--lotblock-botpad) var(--lotblock-leftpad);
            width: 100%;

            @include media-query(phone) {
                padding-top: 0;
            }
        }
    }

    &__sneakpeek-header {
        @extend .notfoundblock;
        @include responsive(margin-top, (
            default: space(3.75),
            phone: space(1)
        ));
        @include responsive(margin-bottom, (
            default: -0.4 * $notfoundblock-y-step * $notfoundblock-steps,
            phone:   -0.6 * $notfoundblock-y-step * $notfoundblock-steps
        ));
        @include responsive(margin-left, (
            default: 0,
            phone: col-width(2)
        ));
        @include responsive(margin-right, (
            default: 0,
            phone: col-width(1)
        ));
        position: static;
        width: 100%;

        .fancytitle__line {            
            &:nth-child(1) {
                margin-left: space(-2.5);
            }
            &:nth-child(2) {
                margin-left: space(2.5);
            }
        }
    }

    // text styles
    .tag {
        margin-bottom: space(0.25);
    }

    h1 {
        @extend .text--auction-huge;
    }

    .underlink#{$state-active} {
        .underlink__content::after {
            display: block;
        }
    }

}
