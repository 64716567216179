
// GLOBAL FUNCTIONS & MIXINS
///////////////////////////////////////////////////////////
// rem calculations are translated from mocks where 1rem = 16px
@function rem($pixel-val) {
    @return (1rem * $pixel-val) / 16;
}

// our spacing system is based on 18px
@function space($multiplier) {
    @return rem(18) * $multiplier;
}

// this lets us apply the same value to multiple properties
@mixin multiple-props($props, $val) {
    @each $prop in $props {
        #{$prop}: $val;
    }
}

// this lets apply position rules to more than one property at once:
//     @include position(relative, left right, 1rem, top bottom, 2rem);
// would render to:
//     position: relative;
//     left: 1rem;
//     right: 1rem;
//     top: 2rem;
//     bottom: 2rem;
// (the second list of properties is optional)
@mixin position($position, $props: null, $val: null, $props2: null, $val2: null, $props3: null, $val3: null, $props4: null, $val4: null, $z-index: null, $z-layer: null) {
    @if $position {
        position: $position;
    }

    @if $props and $val {
        @include multiple-props($props, $val);
    }
    @if $props2 and $val2 {
        @include multiple-props($props2, $val2);
    }
    @if $props3 and $val3 {
        @include multiple-props($props3, $val3);
    }
    @if $props4 and $val4 {
        @include multiple-props($props4, $val4);
    }

    @if $z-index {
        z-index: $z-index;
    } @else if $z-layer {
        @include z-index($z-layer);
    }
}

@mixin flex($dir: null, $justify: null, $align: null, $wrap: null) {
    display: flex;

    @if $dir {
        flex-direction: $dir;
    }
    @if $justify {
        justify-content: $justify;
    }
    @if $align {
        align-items: $align;
    }
    @if $wrap {
        flex-wrap: $wrap;
    }
}

@mixin grid($inline: false, $columns: null, $rows: null, $gap: null, $column-gap: null, $row-gap: null, $justify-items: null, $align-items: null, $justify-content: null, $align-content: null, $auto-flow: null) {
    @if $inline {
        display: inline-grid;
    } @else {
        display: grid;
    }
    @if $columns {
        grid-template-columns: $dir;
    }
    @if $rows {
        grid-template-rows: $rows;
    }
    @if $gap {
        grid-gap: $gap;
    } @else {
        @if $column-gap {
            grid-column-gap: $column-gap;
        } 
        @if $row-gap {
            grid-row-gap: $row-gap;
        }
    }
    @if $justify-items {
        justify-items: $justify-items;
    }
    @if $align-items {
        align-items: $align-items;
    }
    @if $justify-content {
        justify-content: $justify-content;
    }
    @if $align-content {
        align-content: $align-content;
    }
    @if $auto-flow {
        grid-auto-flow: $auto-flow;
    }
}

@mixin background-image($size: cover, $position: center center, $repeat: no-repeat) {
    background-position: $position;
    background-repeat: $repeat;
    background-size: $size;
}


// SASS UTILS
///////////////////////////////////////////////////////////
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function svg-encode($svg) {
    $namespace: ' xmlns="http://www.w3.org/2000/svg"';
    
    @if not str-index($svg, $namespace) {
        $svg: str-insert($svg, $namespace, 5);
    }

    $encode: ('"': "%22", "#": "%23", "%": "%25", "<": "%3C", ">": "%3E", "{": "%7B", "}": "%7D");
    $string: "";
    $character: "";

    @for $i from 1 through str-length($svg) {
        $character: str-slice($svg, $i, $i);

        @if index(map-keys($encode), $character) {
            $character: map-get($encode, $character);
        }

        $string: $string + $character;
    }

    @return url("data:image/svg+xml,#{$string}");
}

