
// GENERAL TIER MIXINS
///////////////////////////////////////////////////////////
@mixin tier-fullbleed($bg: null, $text: null, $topborder: null, $z-layer: arbitrary) {
    @if $bg and $text {
        @include background($bg, $text);
    } @else if $bg {
        @include background($bg);
    }

    @if $topborder {
        @include borders(top, $topborder);
    }

    @include position(relative, $z-layer: $z-layer);
    padding-bottom: space(4);
    padding-left: var(--col-gutter-outer-left);
    padding-right: var(--col-gutter-outer-right);
    padding-top: space(4);

    @include media-query(phone) {
        @include multiple-props(padding-top padding-bottom, space(2.5));
    }

    @at-root .view #{&} {
        margin-left: calc(-1 * var(--col-gutter-outer-left));
        margin-right: calc(-1 * var(--col-gutter-outer-right));
        margin-top: -2px;
    }
}

@mixin tier-fullbleed-change-colors($bg: null, $text: null, $topborder: null) {
    @if $bg and $text {
        @include background($bg, $text);
    } @else if $bg {
        @include background($bg);
    }

    @if $topborder {
        @include borders-set-border-color($topborder);
    }
}
