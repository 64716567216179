.countdown {
    display: inline-block;
    
    &__bold {
        @include font(mono, bold);
    }
    &__medium {
        @include font(mono, medium);
    }
    &__extra {
        @extend .text--mono-small;
        color: $color-good;
    }

    @keyframes tada {
        from {
            transform: scale3d(1, 1, 1);
        }

        10%, 20% {
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        }

        30%, 50%, 70%, 90% {
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        }

        40%, 60%, 80% {
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        }

        to {
            transform: scale3d(1, 1, 1);
        }
    }

    &--expired {
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-name: tada;
        color: $color-bad;
    }
}
