
// STICKY SCROLLER
///////////////////////////////////////////////////////////
@mixin stickyscroller-target($top: 0, $mq: not-phone) {
    --offset: 0;

    @include media-query($mq) {
        @include position(sticky, top, $top);
        backface-visibility: hidden;
        transform: translateZ(0);
        will-change: top;

        &--sticky {
            top: var(--offset);
        }
        &--measuring {
            position: relative;
        }
    }
}

@mixin stickyscroller-target-change-top($top: 0, $mq: not-phone) {
    @include media-query($mq) {
        top: $top;

        &--sticky {
            top: var(--offset);
        }
    }
}
