
// THE TABULAR
// 
// this makes rows that look like:
//
// LABEL....................VALUE
// ANOTHER LABEL.............LINK
// ......................LINK TWO
//
// or if you specify a value width with tabular-set-value('width', XXX):
//
// LABEL.................VALUE
// ANOTHER LABEL.........LINK
// ......................LINK TWO
//
// the tabular has markup that looks like this:
//
// <div class='tabular'>
//     <div class='tabular__label'><span>label</span></div>
//     <div class='tabular__value'><span>value</span></div>
// </div>
// <div class='tabular'>
//     <div class='tabular__label'><span>another label</span></div>
//     <div class='tabular__value'><a>link</a><a>link two</a></div>
// </div>
///////////////////////////////////////////////////////////
$tabular-line-height: $base-fontsize * $lineheight-roomy;
$tabular-width-of-longest-date: 8.5rem; // when date is at default font size

.tabular {
    @include flex($justify: space-between, $align: flex-end);
    background: var(--bg);
    line-height: $tabular-line-height;
    width: 100%;

    &__label {
        @include position(relative, $z-layer: arbirary);
        align-self: stretch; // turn off for just single line of dots
        background: inherit;
        flex: 1;
        overflow: hidden;

        > span,
        &__content {
            @include position(absolute, $z-index: 2);
            @include text-overflow(ellipsis);
            $pad: 0.15em;
            background: inherit;
            margin-left: -1 * $pad;
            max-width: calc(100% - 3.4ch);
            padding: 0 $pad;
        }

        &__content {
            &--notphone {
                @include hide-on(phone);
            }
            &--phone {
                @include show-on(phone);
            }
        }

        &::before {
            @include position(absolute, $z-index: 1);
            content: '................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................';
            // below line is so chromium based screen readers won't read out the ellipses, doesn't seem like there's a solution for other browsers atm
            content: '................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................' / "";
            // direction: rtl;
            height: 100%;
            width: 100%;
            word-break: break-word;
        }
    }

    &__value {
        flex-grow: 0;
        margin-right: 0.15em;

        > a,
        > span {
            display: block;
        }

        &--no-direct-child-line-break {
            > a,
            > span {
                @include text-overflow(ellipsis);
            }
        }

        &--films {
            @extend .text--film-title;
            @extend .tabular__value--no-direct-child-line-break;

            a:hover, a:focus-visible {
                color: $color-grey3;
            }
        }

        &--big {
            font-size: rem(16);
        }

        &--bold {
            color: $color-bad;
        }
    }

    // variants
    &--gap-above {
        margin-top: space(0.75);
    }

    &--bold {
        @include font-weight(bold);
    }

    &--just-the-label {
        .tabular__label {
            // if we're unabsoluting the __content, we need this 
            // padding to ensure text doesn't take up full
            // width of the label.
            padding-right: 3.4ch;

            &__content {
                position: relative;
                white-space: normal;
            }

            &::before {
                bottom: 0;
                height: auto;
                word-break: normal;
            }
        }
    }

    // text styles
    &__default-size {
        font-size: $base-fontsize;
    }

    &__dimension-divider {
        @include responsive(margin-left margin-right, (
            default: 1ch,
            phone: 0.5ch
        ));
    }
}


// TABULAR FUNCTIONS & MIXINS
///////////////////////////////////////////////////////////
@mixin tabular-set-label($prop, $value) {
    .tabular {
        &__label {
            #{$prop}: $value;
        }
    }
}
@mixin tabular-set-value($prop, $value) {
    .tabular {
        &__value {
            #{$prop}: $value;
        }
    }
}
@mixin tabular-set-bg-color($bg, $transition: null) {
    .tabular {
        background: $bg;
    }
}

@mixin tabular-set-fontsize($size: 10) {
    .tabular {
        font-size: rem($size);
        line-height: rem($size) * $lineheight-roomy;

        &__value--big {
            font-size: rem($size) * 1.33;
        }

        &__value--films {
            font-size: rem($size) * 1.166;
        }

        &__default-size {
            font-size: rem($size);
        }
    }
}

@mixin tabular-center-label() {
    .tabular {
        &__label {
            padding-right: 0;
            text-align: center;

            &::before {
                left: 0;
            }
        }
    }
}
