
// VARIABLES
///////////////////////////////////////////////////////////
$input-height: 4.5em;

// BUTTONS
///////////////////////////////////////////////////////////
.button {
    @include borders(all, $color-black);
    align-items: center;
    cursor: pointer;
    display: flex;
    height: calc(#{$input-height} + 2 * var(--border-width)); // 2px for borders
    justify-content: center;
    padding: 0 space(2);
    user-select: none;
    white-space: nowrap;

    @at-root .app--supports-hairlines:not(.app--chrome) #{&} {
        // the solution for hairlines on chrome actually is using
        // a 1px border image with a half-pixel line, but safari
        // and firefox actually support 0.5px lines so we only
        // need to add 1px for borders here
        
        // height: calc(#{$input-height} + 1px);
    }

    &#{$state-disabled},
    &[disabled] {
        @include borders-set-border-color($color-disabled);
        background: $color-disabled;
        color: $color-white;
        pointer-events: none;
    }

    &:not(#{$state-disabled}):hover,
    &:not([disabled]):hover,
    &:not(#{$state-disabled}):focus-visible,
    &:not([disabled]):focus-visible {
        background: $color-black;
        color: $color-white;
    }

    &:not(#{$state-disabled}):active,
    &:not([disabled]):active {
        background: $color-grey1;
        color: $color-white;
    }

    &--cta {
        background: $color-black;
        color: $color-white;

        &:not(#{$state-disabled}):hover,
        &:not([disabled]):hover,
        &:not(#{$state-disabled}):focus-visible,
        &:not([disabled]):focus-visible {
            background: $color-white;
            color: $color-black;
        }

        &:not(#{$state-disabled}):active,
        &:not([disabled]):active {
            background: $color-grey1;
        }
    }

    &--white {
        background: $color-white;
        color: $color-black;

        &:not(#{$state-disabled}):hover,
        &:not([disabled]):hover,
        &:not(#{$state-disabled}):focus-visible,
        &:not([disabled]):focus-visible {
            background: $color-black;
            color: $color-white;
        }

        &:not(#{$state-disabled}):active,
        &:not([disabled]):active {
            background: $color-grey1;
        }
    }

    &--grey0 {
        background: $color-grey0;
        color: $color-black;

        &:not(#{$state-disabled}):hover,
        &:not([disabled]):hover,
        &:not(#{$state-disabled}):focus-visible,
        &:not([disabled]):focus-visible {
            background: $color-black;
            color: $color-white;
        }

        &:not(#{$state-disabled}):active,
        &:not([disabled]):active {
            background: $color-grey1;
            color: $color-black;
        }
    }

    &--white-border {
        @include borders-set-border-color($color-white);
    }
}


//  MENUS
///////////////////////////////////////////////////////////
.menuitem {
    $self: &;
    cursor: pointer;

    &#{$state-disabled} {
        opacity: 0.25;
        pointer-events: none;
    }

    &--radio {
        align-items: center;
        display: flex;
    }

    &__radiobox {
        $box-size: space(1);
        $border-size: 1px;
        background: $color-white;
        border: $border-size solid $color-black;
        border-radius: 100%;
        height: $box-size;
        margin-right: space(0.5);
        position: relative;
        width: $box-size;
        
        @at-root #{$self}:focus #{&} {
            border-color: $color-good;
        }
        @at-root #{$self}#{$state-highlighted} #{&} {
            border-color: $color-good;
        }

        &::after {
            $check-size: $box-size * 0.4;
            @include multiple-props(width height, $check-size);
            @include position(absolute, top left, 50%);
            background-color: #ddd;
            border-radius: 100%;
            content: '';
            display: none;
            transform: translate(-50%, -50%);

            @at-root #{$self}#{$state-highlighted} #{&} {
                display: block;
            }

            @at-root #{$self}#{$state-selected} #{&} {
                background-color: black;
                display: block;
            }
        }
    }
}


// FIELDS
///////////////////////////////////////////////////////////
$controls-field-label-minheight: space(2);

@mixin field-base {
    $self: &;
    position: relative;

    &__label {
        @include flex($justify: space-between, $align: flex-end);
        font-size: rem(10);
        min-height: $controls-field-label-minheight;
        padding: space(0.33) 0;
        
        // hiding with display: none doesn't seem to fly for
        // screen readers
        &#{$state-hidden} {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            z-index: -1;
        }
    }
    &#{$state-disabled} &__label {
        color: $color-disabled;
    }

    &__label__text {
        text-transform: uppercase;

        @at-root #{$self}#{$state-error} #{&} {
            color: $color-bad;
        }
    }

    &__label__help-text {
        @extend .text--color-grey3;
        margin-left: 0.5em;
    }

    &__errors {
        @extend .text--mono-small;
        background-color: $color-bad;
        /* stylelint-disable indentation */
        box-shadow: 0 rem(1)  rem(1) transparentize($color-black, 0.94), 
                    0 rem(2)  rem(2) transparentize($color-black, 0.94), 
                    0 rem(4)  rem(3) transparentize($color-black, 0.94), 
                    0 rem(6)  rem(4) transparentize($color-black, 0.94),
                    0 rem(10) rem(5) transparentize($color-black, 0.94);
        /* stylelint-enable indentation */
        color: $color-white;
        line-height: $lineheight-tight;
        overflow: hidden;
        perspective: rem(1000);
        text-align: left;
        transform-origin: 50% 0%;

        @at-root #{$self}#{$state-disabled} #{&} {
            display: none;
        }

        &__text {
            padding: space(0.5) space(2);
        }
    }
}

@mixin textbox-common {
    @include field-base;
    $self: &;

    &__control {
        @include borders(all, $color-black);

        @at-root #{$self}#{$state-focused} #{&} {
            @include borders-set-border-color($color-good);
        }

        @at-root #{$self}#{$state-error} #{&} {
            @include borders-set-border-color($color-bad);
        }

        @at-root #{$self}#{$state-disabled} #{&} {
            @include borders-set-border-color($color-disabled);
        }

        @at-root #{$self}--white-border #{&} {
            @include borders-set-border-color($color-white);
        }
    }

    &__input {
        background: $color-white;
        box-shadow: none;
        height: $input-height;
        letter-spacing: -0.05em;
        padding: 0 space(2);
        text-transform: none;
        width: 100%;

        &::placeholder {
            color: $color-grey3;
            opacity: 1;

            @at-root #{$self}--black #{&} {
                color: $color-white;
            }
        }
        &::-webkit-input-placeholder {
            line-height: normal;
        }

        @at-root #{$self}#{$state-error} #{&} {
            color: $color-bad;
        }
        @at-root #{$self}#{$state-disabled} #{&} {
            color: $color-disabled;

            &::placeholder {
                color: $color-disabled;
            }
        }

        @at-root #{$self}--black #{&} {
            background: $color-black;
            color: $color-white;
        }

        &[type=number] {
            @at-root #{$self}#{$state-disabled} #{&},
            &:not(:hover):not(:focus) {
                appearance: textfield;
            }

            &:not(:hover):not(:focus)::-webkit-inner-spin-button,
            &:not(:hover):not(:focus)::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }
}

@mixin dropdown-common {
    @include textbox-common;

    $dropdown-open-speed: map-deep-get($transitions, dropdown, speed);

    .menu {
        --scrollbar-width: 0px;
        @include scrollbar-style($bg: $color-white, $thumb: $color-black);
        @include transition(unquote('box-shadow, max-height'), dropdown);
        left: 0;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 100%;
        transition-delay: 0ms, $dropdown-open-speed;
        z-index: 2;

        &:hover, &:focus-visible {
            --scrollbar-width: #{$scrollbar-width};
        }

        &::before {
            @include position(absolute, top left bottom right, 0);
            @include transition(transform, dropdown);
            background: $color-white;
            content: '';
            transform: scale3d(1, 0, 1);
            transform-origin: top;
            transition-delay: 0ms;
            z-index: 1;
        }
    }

    &#{$state-open} .menu {
        /* stylelint-disable indentation */
        box-shadow: 0 rem(2)  rem(1)  transparentize($color-black, 0.94), 
                    0 rem(4)  rem(2)  transparentize($color-black, 0.94), 
                    0 rem(8)  rem(4)  transparentize($color-black, 0.94), 
                    0 rem(16) rem(8)  transparentize($color-black, 0.94),
                    0 rem(32) rem(16) transparentize($color-black, 0.94);
        /* stylelint-enable indentation */
        height: auto;
        max-height: rem(200);
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        transition-delay: $dropdown-open-speed * 0.5, 0ms;
        transition-duration: $dropdown-open-speed, 0ms;

        &::before {
            transform: scale3d(1, 1, 1);
            transition-delay: 0ms;
        }
    }

    .menuitem {
        @include transition(opacity, dropdown);
        @include borders(left right bottom, $color-black);
        background-color: $color-white;
        opacity: 0;
        padding: space(1.25) space(2);
        pointer-events: none;
        position: relative;
        transition-delay: 0ms;
        z-index: 2;

        &#{$state-highlighted} {
            background-color: $color-black;
            color: $color-white;
        }

        &#{$state-selected} {
            background-color: $color-good;
            color: $color-white;
        }
    }
    &#{$state-open} .menuitem {
        opacity: 1;
        pointer-events: auto;
        transition-delay: $dropdown-open-speed * 0.5;
    }
}

.textinput {
    @include textbox-common;
}

.moneyinput {
    @include textbox-common;
    $self: &;

    &__control {
        position: relative;
    }

    &__currency {
        @include position(absolute, left, space(2), top,  calc((100% - 1em) / 2));
        font-size: 2rem;
        pointer-events: none;

        @at-root #{$self}#{$state-empty}:not(#{$state-focused}):not(#{$state-error}):not(#{$state-disabled}) #{&} {
            color: $color-grey3;
        }

        @at-root #{$self}#{$state-disabled} #{&} {
            color: $color-disabled;
        }

        @at-root #{$self}#{$state-error}:not(#{$state-disabled}) #{&} {
            color: $color-bad;
        }
    } 

    &__input {
        padding-left: space(4);
    }
}

.stripeinput {
    @include textbox-common;

    &__input {
        display: flex;

        > div {
            flex-grow: 1;
        }

        iframe {
            @include position(absolute, left right, 0, top, calc(50% - 0.666em));
        }
    }
}

.select {
    @include dropdown-common;

    &__display {
        cursor: pointer;
        position: relative;

        &::after {
            @include position(absolute, right, space(2), top, calc((100% - 1em) / 2));
            content: '\2193';
            font-size: 1rem;
            pointer-events: none;
        }
    }

    &__input {
        opacity: 1;
        padding-right: calc(#{space(2)} + 2ch);
        pointer-events: none;
    }
}

.checkbox {
    $self: &;
    $box-size: space(1);
    $box-right-margin: space(0.5);
    @include field-base;

    &__control {
        align-items: center;
        cursor: pointer;
        display: flex;

        @at-root #{$self}#{$state-error} #{&} {
            color: $color-bad;
        }
    }
    
    &__box {
        @include borders(all, $color-black);
        background: $color-white;
        height: $box-size;
        margin-right: $box-right-margin;
        position: relative;
        width: $box-size;
        
        @at-root #{$self}#{$state-focused} #{&} {
            @include borders-set-border-color($color-good);
        }

        @at-root #{$self}#{$state-error} #{&} {
            @include borders-set-border-color($color-bad);
        }

        &::after {
            @include position(absolute, left right top bottom, rem(4));
            background-color: #ddd;
            content: '';
            display: none;

            @at-root #{$self}#{$state-empty}:hover #{&} {
                display: block;
            }

            @at-root #{$self}#{$state-empty}:focus-visible #{&} {
                display: block;
            }

            @at-root #{$self}#{$state-selected} #{&} {
                background-color: black;
                display: block;
            }
        }
    }

    &__errors {
        background-color: transparent;
        box-shadow: none;
        color: $color-bad;
        text-align: left;

        &__text {
            padding-left: $box-size + $box-right-margin;

            &::before {
                @include position(absolute, top left, #{space(0.33)});
                content: '\21B3';
                font-size: rem(13);
            }
        }
    }
}

.radioset {
    @include field-base;

    .menu__content {
        display: flex;
    }

    .menuitem {
        padding: space(0.5) space(2) space(0.5) 0;
        user-select: none;
    }
}
