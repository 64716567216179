
// NEWSLETTER SIGNUP TIER
///////////////////////////////////////////////////////////
.newslettertier {
    $self: &;
    @include tier-fullbleed($bg: $color-grey0, $topborder: $color-black);

    &--black {
        @include tier-fullbleed-change-colors($bg: $color-black, $text: $color-white);
        @include underlink-set-color($color-white);

        .textinput {
            @extend .textinput--black;
            @extend .textinput--white-border;
        }

        .button {
            @extend .button--white;
            @extend .button--white-border;
        }
    }

    &--space-below {
        margin-bottom: var(--auctiontier-botpad);
    }

    &__cols {
        @include flex($justify: space-between);
        @include responsive(padding-top padding-bottom, (
            default: space(2),
            mobile: 0
        ));

        @include media-query(tablet-lg) {
            flex-wrap: wrap;
        }

        @include media-query(tablet-sm-and-below) {
            flex-direction: column;
        }
    }

    &__col {
        &--intro {
            @include responsive(margin-left, (
                default: col-width(2),
                tablet-lg: col-width(1),
                tablet-sm-and-below: 0
            ));
            flex: 1;

            @include media-query(full) {
                @include tabular-set-label(padding-right, #{col-width(0.5)});
            }

            @include media-query(mobile) {
                flex-basis: 100%;
                margin-bottom: space(1);
                width: 100%;
            }

            @include media-query(tablet-lg) {
                .tabular {
                    width: col-width(6);
                }
            }
        }

        &--form {
            @include responsive(width, (
                default: #{col-width(4)},
                tablet-lg: #{col-width(8)},
                tablet-sm-and-below: 100%
            ));
            @include responsive(padding-right, (
                default: space(2),
                tablet-lg: col-width(1),
                tablet-sm-and-below: 0
            ));
            @include responsive(padding-left, (
                tablet-lg: col-width(1)
            ));
            position: relative;
        }

        &--social {
            @include tabular-set-value(min-width, col-width(1));
            align-self: flex-end;
            flex: 0;
            margin-right: col-width(2);
            min-width: col-width(2);

            @include media-query(mobile) {
                // hackily matches width of "Privacy Policy" in footer,
                // which goes longer than it's min-width at tablet-lg size
                @include tabular-set-value(min-width, 14.4ch);
            }

            @include media-query(tablet) {
                margin-right: col-width(1);
                min-width: col-width(3);
            }

            @include media-query(tablet-sm-and-below) {
                margin-right: 0;
                margin-top: space(1);
                width: 100%;
            }

            @include media-query(phone) {
                @include tabular-set-value(min-width, col-width(5));
            }
        }
    }

    &__intro {
        @include responsive(max-width, (
            default: 9em,
            tablet-lg: 15em,
            tablet-sm-and-below: none
        ));
        line-height: $tabular-line-height;
        margin-right: space(2);
    }

    .form {
        margin: 0;
        padding-bottom: 0;
        width: 100%;

        &__row {
            &--newsletter-msg {
                @include media-query(not-phone) {
                    @include position(absolute, top, 100%, left right, 0);
                }
            }
        }

        &__cell {
            &--newsletter-signup-btn {
                flex: 0;
                margin-left: 0;
            }
        }

        .textinput__errors {
            @include position(absolute, top, 100%, left right, 0, $z-index: 1);
        }
    }
    &--black .form {
        &__msg {
            color: $color-white;
        }
    }

    // sticky variant for upcoming Lot pages
    &--sticky {
        // @extend .newslettertier--black;
        @include position(sticky, bottom, calc(-1 * var(--border-width)));
        @include borders(bottom, $color-grey0);
        @include responsive(--input-height, (
            default: 4.5em
        ));
        @include responsive(--input-padding-horizontal, (
            default: #{space(3)},
            mobile: var(--col-gutter)
        ));
        @include responsive(--button-padding-horizontal, (
            default: #{space(4)},
            tablet: #{space(3)},
            phone: var(--col-gutter)
        ));
        border-top: none;
        padding: 0;

        #{$self}__cols {
            flex-direction: column;
            padding: 0;
        }

        #{$self}__col {
            &--intro {
                --bg: #{$color-black};
                --text: #{$color-grey0};
                @include position(absolute, bottom, 100%, left, 0);
                @include borders(top right, $color-black);
                background: var(--bg);
                color: var(--text);
                margin-bottom: 0;
                margin-left: 0;
                padding: space(0.75) space(1) space(0.75) var(--input-padding-horizontal);
                width: auto;
            }
            &--form {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
            &--social {
                display: none;
            }

            &--intro .tabular {
                @include media-query(phone) {
                    font-size: 87.5%;
                    line-height: 1.2;
                    max-width: 15em;
                }
            }
        }

        .textinput__input,
        .button {
            @include responsive(font-size, (
                default: 1.75em,
                phone: 1.4em
            ));
        }
        
        .textinput__input {
            height: var(--input-height);
            padding: 0 var(--input-padding-horizontal);
        }

        .textinput__control {
            border-bottom: none;
            border-left: none;
        }

        .button {
            border-bottom: none;
            border-right: none;
            height: calc(var(--input-height) + 2 * var(--border-width));
            min-width: calc(9em + var(--button-padding-horizontal));
            padding: 0 var(--button-padding-horizontal);
        }

        .form__row--newsletter-msg {
            @include position(absolute, bottom, space(0.75), left, var(--input-padding-horizontal), top right, auto);
            text-align: left;

            @include media-query(phone) {
                bottom: space(0.5);
            }
        }
    }
}
