
// TITLE
///////////////////////////////////////////////////////////
.title {
    @include position(relative);

    h1 {
        @extend .text--film-title-huge;
        margin-bottom: space(3);
        text-align: center;
    }

    &__hero {
        @include flex($align: center);
        @include position(relative, $z-layer: arbitrary);
        margin-bottom: space(6);

        @include media-query(phone) {
            flex-direction: column;
        }

        &__media {
            @include cols(9, $cols-tablet: 6, $cols-phone: 12);
            @include responsive(padding-left, (
                default: col-width(3),
                mobile: 0
            ));
            align-self: flex-start;
            flex: 1;

            @include media-query(not-phone) {
                @include position(sticky, top, calc(var(--headers-height) + var(--view-toppad)));
            }
        }

        &__info {
            @include cols(3, $cols-tablet: 6, $cols-phone: 12);
            padding-left: space(2);

            @include media-query(phone) {
                margin-top: space(2);
                padding-left: 0;
            }
        }

        &__description {
            @extend .text--body-big;
            margin-bottom: space(2);

            @media screen and (min-width: #{$tablet-max}) and (max-width: 1350px) {
                font-size: rem(14);
            }
        }

        &__social {
            a {
                margin-right: space(0.5);
            }
        }

        .button {
            margin-bottom: space(2);
        }
    }

    &__lots {
        margin-bottom: space(6);

        @include media-query(phone) {
            margin-bottom: space(3);
        }

        .lotstier {
            border-bottom: none;
        }
    }

    &__auctions {
        @include position(relative);
        @include borders(top);
        margin-bottom: space(6);
        padding-top: var(--lotblock-toppad);

        &__title {
            @include tier-title();
        }
    }
}
