
// TITLE TIER
///////////////////////////////////////////////////////////
.titletier {
    @include flex($wrap: wrap);

    @include media-query(phone) {
        margin: 0 calc(-1 * var(--col-gutter));
    }

    .titleblock {
        @include responsive(width, (
            default: 25%,
            tablet: 33.3%,
            phone: 50%
        ));
    }

    .buttontier {
        width: 100%;
    }
}
