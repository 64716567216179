
// TRANSITION VARIABLES
///////////////////////////////////////////////////////////
$transitions: (
    change-color: (
        speed: 200ms,
        ease: ease-in-out
    ),
    hover: (
        speed: 300ms,
        ease: ease-in-out
    ),
    dropdown: (
        speed: 200ms,
        ease: ease-in
    ),

    // vue transitions
    fade: (
        speed: 300ms,
        ease: ease-in-out
    ),
    slide: (
        speed: 600ms,
        ease: ease-in-out
    ),
    field-error: (
        speed: 500ms,
        ease: ease-out
    ),
    progressbar: (
        speed: 200ms,
        ease: ease-out
    )
);


// TRANSITION FUNCTIONS & MIXINS
///////////////////////////////////////////////////////////
@mixin transition($props, $flavor: hover) {
    transition-duration: map-deep-get($transitions, #{$flavor}, speed);
    transition-property: $props;
    transition-timing-function: map-deep-get($transitions, #{$flavor}, ease);

    @if map-deep-get($transitions, #{$flavor}, delay) {
        transition-delay: map-deep-get($transitions, #{$flavor}, delay);
    }
}

@mixin parallax($transform, $transform-only: false) {
    transform: $transform translateZ(0);

    @if not $transform-only {
        backface-visibility: hidden;
        will-change: transform;
    }
}


// TRANSITION CLASSES
///////////////////////////////////////////////////////////
.fade-enter-active,
.fade-leave-active {
    @include transition(opacity, fade);
}
.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
    @include transition(transform, slide);
}
.slide-enter,
.slide-leave-to {
    transform: translateX(100%);
}

.field-error-leave-active,
.field-error-enter-active {
    @include transition(transform max-height, field-error);
    max-height: calc(#{space(1)} + 1em);
}
.field-error-enter,
.field-error-leave-to {
    max-height: 0;
    transform: rotate3d(1, 0, 0, 90deg);
}

.progressbar-leave-active {
    transition-duration: map-deep-get($transitions, progressbar, speed);

    .progressbar__current {
        @include transition(transform, progressbar);
        transform: scale3d(1, 1, 1) !important;
    }
}
