.progressbar {
    @include position(fixed, left right, 0, top bottom, 0, $z-layer: progressbar);
    background-color: $color-grey0;
    pointer-events: none;

    &__current {
        background-color: $color-white;
        height: 100%;
        transform: scale3d(1, 0, 1);
        transform-origin: top center;
        width: 100%;
    }
    
    // when inside a grey-backgrounded area
    @at-root .background--grey #{&} {
        background-color: mix($color-grey0, $color-grey1, 75%);

        &__current {
            background-color: $color-grey0;
        }
    }

    // compact progress bar
    @mixin compact-progressbar() {
        @include z-index(progressbar-compact);
        left: auto;
        width: 3px;

        @include media-query(phone) {
            width: 2px;
        }

        &__current {
            background-color: $color-good;
        }
    }
    
    @at-root .view--compact-progressbar #{&} {
        @include compact-progressbar();
    }

    // inside the content area of a .splittier
    @at-root .splittier__col--content #{&} {
        @include compact-progressbar();
    }
}
