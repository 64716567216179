
// VIEWS
// (top-level container of view components)
///////////////////////////////////////////////////////////
.view {
    @extend .background--white;
    @include responsive(--view-toppad, (
        default: space(5),
        mobilenav: space(3)
    ));
    
    height: 100%;
    min-height: 100vh;
    padding-left: var(--col-gutter-outer-left);
    padding-right: var(--col-gutter-outer-right);
    padding-top: calc(var(--headers-height) + var(--view-toppad));

    &--authform {
        @extend .background--grey;

        @include media-query(not-phone) {
            --view-toppad: #{space(6)};
        }
    }

    &--three-cols {
        @extend .background--three-cols-responsive;
    }

    &--four-cols {
        @extend .background--four-cols-responsive;
    }

    &--behind-header {
        --view-toppad: 0px;
        padding-top: 0;
    }

    &--below-logo {
        --view-toppad: #{space(0.33)};
    }
}
