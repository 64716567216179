
// TAG
// (e.g., auction status tag on Auction Detail Page)
///////////////////////////////////////////////////////////
$tag-vert-pad: space(0.33);

// used elsewhere but inherited from .text--mono-small in this file
$tag-font-size: rem(10);

.tag {
    @extend .text--mono-small;
    display: inline-block;
    padding: $tag-vert-pad space(0.5);

    &--status-open {
        @include borders(all, $color-white);
        background: $color-black;
        color: $color-white;
    }

    &--status-soon {
        @include borders(all, $color-white);
        background: $color-grey3;
        color: $color-white;
    }

    &--status-closed {
        @include borders(all, $color-black);
        background: $color-white;
        color: $color-black;
    }
}
