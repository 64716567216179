
// TEXTBLOCK
// A block with a header followed by body text content;
// can optionally have a tabular inside, with body text
// max-width matched to tabular label width
///////////////////////////////////////////////////////////
.textblock {
    @include borders(top);
    padding: space(3) 0;

    @include media-query(phone) {
        padding: space(2) 0;
    }

    &__body,
    .tabular {
        margin-top: space(1);
    }

    &__body {
        @include flex($align: flex-start);
        line-height: 1.5;

        &__text {
            flex: 1;
            padding-right: space(2);

            p {
                max-width: 22em;
            }
        }
        &__sidebar--bordered {
            position: relative;

            &::after {
                @include borders(left);
                @include position(absolute, left, space(-0.5), top bottom, 0);
                content: '';
            }
        }
    }
    &--stack-on-phone &__body {
        @include media-query(phone) {
            flex-direction: column;

            &__sidebar {
                margin-top: space(1);
            }
            &__sidebar--bordered::after {
                display: none;
            }
        }
    }

    .tabular {
        font-size: rem(10);

        & + .tabular {
            margin-top: 0;
        }
    }
}
