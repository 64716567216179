
// AUCTION BLOCKS
// the module for an auction that appears on home + films pages
///////////////////////////////////////////////////////////
/* stylelint-disable indentation */
$auctiontier-grid-columns: 
    [col0]  1fr
    [col1]  1fr
    [col2]  1fr
    [col3]  1fr
    [col4]  1fr
    [col5]  1fr
    [col6]  1fr
    [col7]  1fr
    [col8]  1fr
    [col9]  1fr
    [col10] 1fr
    [col11] 1fr
    [col12];

$auctiontier-grid-rows: 
    [row0] auto
    [row1] auto
    [row2] auto
    [row3] auto
    [row4] auto
    [row5] auto
    [row6] auto
    [row7] auto
    [row8] auto
    [row9];
/* stylelint-enable indentation */

$auctiontier-img-min-height-ratio: 0.618;

:root {
    @include responsive(--auctiontier-botpad, (
        default: #{space(8)},
        tablet: #{space(6)},
        phone:   #{space(3)}
    ));
}

.auctiontier {
    @include z-index(arbitrary);
    display: grid;
    grid-template-columns: $auctiontier-grid-columns;
    grid-template-rows: $auctiontier-grid-rows;
    line-height: $tabular-line-height;
    margin-bottom: var(--auctiontier-botpad);
    position: relative;

    &__img {
        // the below height properties are needed for object-fit to work 
        // properly without getting huge when a portrait imgae
        height: 100%;
        max-height: 90vh;

        @media screen and (max-height: 600px) {
            max-height: 500px;
        }

        @include media-query(phone) {
            margin: 0 calc(-0.5 * var(--col-gutter));
        }
    }

    $parallax-hero: -7.5vh;
    $parallax-img-big: -10vh;
    $parallax-img-sub: -5vh;
    $parallax-badge: -4vh;
    $parallax-infobox: -2.5vh;

    // an auction tier will either use a hero...
    &__img--hero {
        @include parallax(translateY(calc(var(--progress) * #{$parallax-hero})));
        grid-column: col1 / span col11;
        grid-row: row1 / span row8;
        min-height: col-width(10, '* #{$auctiontier-img-min-height-ratio}');

        @include media-query(mobile) {
            grid-column: col0 / span col12;
            min-height: col-width(12, '* #{$auctiontier-img-min-height-ratio}');
        }
    }
    
    // ... OR a big + sub image
    &__img--big {
        @include parallax(translateY(calc(var(--progress) * #{$parallax-img-big})));
        z-index: 1;
    }
    &__img--sub {
        @include parallax(translateY(calc(var(--progress) * #{$parallax-img-sub})));
        z-index: 2;
    }
    /*
    &__img--big,
    &__img--sub {
        &:hover, &:focus-visible {
            z-index: 3;
        }
    }
    */

    &__infobox {
        @include parallax(translateY(calc(var(--progress) * #{$parallax-infobox})));
        max-width: rem(500);
        z-index: 4;
    }

    &:hover &__infobox, 
    &:focus-visible &__infobox {
        @include infobox-set-colors($bg: $color-grey0, $text: $color-black);
    }

    // title overlay (badge)
    &__badge {
        @include position(relative, $z-index: 3);
    }

    // layouts
    @mixin grid-img($col, $row, $col-tablet, $row-tablet, $col-phone, $row-phone, $width, $height, $width-tablet, $width-phone) {
        grid-column: col#{$col} / span col#{$col + $width};
        grid-row: row#{$row} / span row#{$row + $height};
        min-height: col-width($width, '* #{$auctiontier-img-min-height-ratio}');

        @include media-query(tablet) {
            @if not($col-tablet) { $col-tablet: $col; }
            @if not($row-tablet) { $row-tablet: $row; }
            grid-column: col#{$col-tablet} / span col#{$col-tablet + $width-tablet};
            grid-row: row#{$row-tablet} / span row#{$row-tablet + $height};
            min-height: col-width($width-tablet, '* #{$auctiontier-img-min-height-ratio}');
        }

        @include media-query(phone) {
            @if not($col-phone) { $col-phone: $col; }
            @if not($row-phone) { $row-phone: $row; }
            grid-column: col#{$col-phone} / span col#{$col-phone + $width-phone};
            grid-row: row#{$row-phone} / span row#{$row-phone + $height};
            min-height: col-width($width-phone, '* #{$auctiontier-img-min-height-ratio}');
        }
    }

    @mixin grid-img-big($col, $row, $col-tablet: null, $row-tablet: null, $col-phone: null, $row-phone: null) {
        .auctiontier__img--big {
            @include grid-img($col, $row, $col-tablet, $row-tablet, $col-phone, $row-phone, $width: 6, $height: 7, $width-tablet: 8, $width-phone: 10);
        }
    }

    @mixin grid-img-sub($col, $row, $col-tablet: null, $row-tablet: null, $col-phone: null, $row-phone: null) {
        .auctiontier__img--sub {
            @include grid-img($col, $row, $col-tablet, $row-tablet, $col-phone, $row-phone, $width: 3, $height: 4, $width-tablet: 5, $width-phone: 7);
        }
    }

    @mixin grid-badge($col, $row, $col-tablet: null, $row-tablet: null, $col-phone: null, $row-phone: null, $offset-x: 0.5, $offset-y: 0.5, $hero-x: left, $hero-y: top) {
        $width: 2;
        $width-tablet: 3;
        $width-phone: 4;

        .auctiontier__badge {
            @include grid-img($col, $row, $col-tablet, $row-tablet, $col-phone, $row-phone, $width: $width, $height: $width, $width-tablet: $width-tablet, $width-phone: $width-phone);
            transform: translate3d(calc(#{$offset-x} * var(--col-gutter)), calc((#{$offset-y} * var(--col-gutter)) + (var(--progress) * #{$parallax-badge})), 0);
        }

        &.auctiontier--has-hero .auctiontier__badge {
            $y: 0;

            @if $hero-y == bottom {
                $y: 7;
            } @else if $hero-y == center {
                $y: 4;
            }

            @if $hero-x == right {
                @include grid-img(10, $y, 9, $y, 8, 0, $width: $width, $height: $width, $width-tablet: $width-tablet, $width-phone: $width-phone);
                transform: translate3d(var(--col-gutter), calc((0.5 * var(--col-gutter)) + (var(--progress) * #{$parallax-badge})), 0);

                svg {
                    transform: translate3d(7.5%, 0, 0);
                }
            } @else {
                @include grid-img(0, $y, 0, $y, 0, 0, $width: $width, $height: $width, $width-tablet: $width-tablet, $width-phone: $width-phone);
                transform: translate3d(calc(-1 * var(--col-gutter)), calc((-0.5 * var(--col-gutter)) + (var(--progress) * #{$parallax-badge})), 0);

                svg {
                    transform: translate3d(-12.5%, 0, 0);
                }
            }
        }
    }

    @mixin grid-infobox($x-pos: null, $y-pos: null) {
        .auctiontier__infobox {
            grid-row: row0 / span row9;
            margin-left: col-width(1, '- #{space(2)}');

            @if $x-pos == left {
                @include responsive(grid-column, (
                    default:   col0 / span col5,
                    tablet-lg: col0 / span col7,
                    tablet-sm: col0 / span col8
                ));
            } @else if $x-pos == center {
                @include responsive(grid-column, (
                    default:   col3 / span col8,
                    tablet-lg: col3 / span col10,
                    tablet-sm: col2 / span col10
                ));
            } @else if $x-pos == right {
                @include responsive(grid-column, (
                    default:   col6 / span col11,
                    tablet-lg: col5 / span col12,
                    tablet-sm: col4 / span col12
                ));
                
                @at-root .auctiontier--has-hero#{&} {
                    @include responsive(grid-column, (
                        full:  col7 / span col12,
                    ));
                }
            }

            @if $y-pos == top {
                align-self: start;
            } @else if $y-pos == center {
                align-self: center;
            } @else if $y-pos == bottom {
                align-self: end;
            }

            @include media-query(phone) {
                grid-column: col0 / span col12;
                grid-row: row8 / span row9;
                margin: calc(#{space(-2)} - 1rem - 4px) 0 0;
            }
        }
    }

    &--layout-left-top {
        @include grid-img-big($col: 6, $row: 1, $col-tablet: 4, $col-phone: 2, $row-phone: 0);
        @include grid-img-sub($col: 4, $row: 5, $col-phone: 0, $row-phone: 2);
        @include grid-badge($col: 2, $row: 7, $col-tablet: 8, $row-tablet: 8, $col-phone: 7, $row-phone: 1, $hero-x: right, $hero-y: center, $offset-y: -0.5);
        @include grid-infobox($x-pos: left, $y-pos: top);
    }
    &--layout-left-center {
        @include grid-img-big($col: 6, $row: 2, $col-tablet: 4, $col-phone: 2, $row-phone: 1);
        @include grid-img-sub($col: 4, $row: 0, $col-phone: 0);
        @include grid-badge($col: 7, $row: 0, $col-tablet: 9, $col-phone: 7, $row-phone: 0, $hero-x: right, $offset-x: -0.5);
        @include grid-infobox($x-pos: left, $y-pos: center);
    }
    &--layout-left-bottom {
        @include grid-img-big($col: 6, $row: 1, $col-tablet: 4, $col-phone: 2);
        @include grid-img-sub($col: 4, $row: 0, $col-tablet: 1, $col-phone: 0);
        @include grid-badge($col: 2, $row: 0, $col-tablet: 6, $col-phone: 6, $row-phone: 0, $hero-x: right, $offset-y: -0.5);
        @include grid-infobox($x-pos: left, $y-pos: bottom);
    }

    &--layout-center-top {
        @include grid-img-big($col: 6, $row: 2, $row-phone: 1, $col-tablet: 4, $col-phone: 2);
        @include grid-img-sub($col: 1, $row: 3, $row-phone: 0, $col-tablet: 0, $col-phone: 0);
        @include grid-badge($col: 9, $row: 1, $col-tablet: 0, $row-tablet: 2, $col-phone: 8, $row-phone: 0, $hero-y: center, $offset-x: -0.5);
        @include grid-infobox($x-pos: center, $y-pos: top);
    }
    &--layout-center-center {
        @include grid-img-big($col: 6, $row: 0, $col-tablet: 4, $col-phone: 2);
        @include grid-img-sub($col: 1, $row: 5, $row-phone: 2, $col-tablet: 0, $col-phone: 0);
        @include grid-badge($col: 0, $row: 4, $col-tablet: 0, $row-tablet: 2, $col-phone: 8, $row-phone: 1, $hero-y: center, $offset-x: -0.5, $offset-y: -0.5);
        @include grid-infobox($x-pos: center, $y-pos: center);
    }
    &--layout-center-bottom {
        @include grid-img-big($col: 0, $row: 1);
        @include grid-img-sub($col: 7, $row: 0, $col-phone: 5);
        @include grid-badge($col: 5, $row: 0, $col-tablet: 5, $row-tablet: 0, $col-phone: 2, $row-phone: 0, $hero-x: right, $hero-y: top, $offset-x: -0.5, $offset-y: -0.5);
        @include grid-infobox($x-pos: center, $y-pos: bottom);
    }

    &--layout-right-top {
        @include grid-img-big($col: 2, $row: 2, $row-phone: 1);
        @include grid-img-sub($col: 0, $row: 1, $row-phone: 0);
        @include grid-badge($col: 3, $row: 0, $col-tablet: 0, $row-tablet: 0, $col-phone: 7, $row-phone: 0, $hero-y: center, $offset-x: -0.5, $offset-y: -0.5);
        @include grid-infobox($x-pos: right, $y-pos: top);
    }
    &--layout-right-center {
        @include grid-img-big($col: 0, $row: 2, $row-phone: 1);
        @include grid-img-sub($col: 5, $row: 0, $col-tablet: 5);
        @include grid-badge($col: 4, $row: 1, $col-tablet: 1, $row-tablet: 1, $col-phone: 2, $row-phone: 0, $hero-y: bottom, $offset-x: -0.5, $offset-y: -0.5);
        @include grid-infobox($x-pos: right, $y-pos: center);
    }
    &--layout-right-bottom {
        @include grid-img-big($col: 2, $row: 0);
        @include grid-img-sub($col: 0, $row: 4);
        @include grid-badge($col: 7, $row: 1, $col-tablet: 0, $row-tablet: 1, $col-phone: 2, $row-phone: 0, $offset-y: -0.5);
        @include grid-infobox($x-pos: right, $y-pos: bottom);
    }
}
