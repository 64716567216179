
// Z-INDEX INDEX
///////////////////////////////////////////////////////////
$z-layers: (
    progressbar,
    applogo,

    // 'arbitrary' is reserved for an element
    // that needs to sit above positioned elements
    // that don't have a z-index, or ones that
    // should go above the fixed logo
    arbitrary,
    above-arbitrary,

    bidblock,

    appfooter,
    appheader,
    lotheader,
    applogo-mobilenav,

    bottombar,
    panel,
    confetti,

    progressbar-compact,

    devgrid
);

@mixin z-index($layer) {
    z-index: #{index($z-layers, $layer)};
}
