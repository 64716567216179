
// GRID DEFINITIONS (12 COLUMN GRID)
///////////////////////////////////////////////////////////
// win width value of 100vw allows us to calculate widths as if
// scrollbars don't take up width (if they are present and DO 
// actually take up width), and then compensate for the scrollbar
// width with var(--col-gutter-outer-right)
$win-width: 100vw;

// the column gutter is used for the left and right outside gutters,
// as well as a standard unit of spacing when room between modules
// is needed in a layout
$col-count: 12;
$col-gutter: space(2);
$col-gutter-extra-space-full-xl: space(2);


// GRID FUNCTIONS & MIXINS
///////////////////////////////////////////////////////////
@function col-width-vars($cols) {
    @return '#{$cols} * (var(--win-width) - (2 * var(--col-gutter))) / #{$col-count}';
}

// the $adjust parameter lets you offset from a calculated column
// width, e.g., if you wanted 3rem less than 8 columns, you could do:
// width: col-width(8, '- 3rem');
@function col-width($cols, $adjust: null) {
    @if $adjust {
        @return calc((#{col-width-vars($cols)}) #{$adjust});
    } @else {
        @return calc(#{col-width-vars($cols)});
    }
}

@mixin cols($cols, $adjust: null, $cols-tablet: null, $adjust-tablet: null, $cols-phone: null, $adjust-phone: null) {

    width: col-width($cols, $adjust);

    @if $cols-tablet {
        @include media-query(tablet) {
            width: col-width($cols-tablet, $adjust-tablet);
        }
    }

    @if $cols-phone {
        @include media-query(phone) {
            width: col-width($cols-phone, $adjust-phone);
        }
    }
}

// CSS CUSTOM PROPERTY DEFAULTS FOR GRID
///////////////////////////////////////////////////////////
:root {
    @include responsive(--col-gutter, (
        default: #{$col-gutter},
        phone:   #{$col-gutter * 0.75},
        full-xl: #{$col-gutter + $col-gutter-extra-space-full-xl}
    ));
    --col-gutter-outer-left: var(--col-gutter);
    --col-gutter-outer-right: calc(var(--col-gutter) - var(--scrollbar-width));

    --win-width: #{$win-width};
    --col-width: #{col-width(1)};
}
