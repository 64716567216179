
// GRID OVERLAY FOR DEVELOPMENT
///////////////////////////////////////////////////////////
.dev-grid {
    @include position(fixed, top left, 0, $z-layer: devgrid);
    // display: none;
    pointer-events: none;

    &__col {
        @include position(fixed, top bottom, 0);
        border-left: 1px solid #ffd700;
        opacity: 0.5;

        @for $i from 1 through $col-count + 1 {
            &:nth-child(#{$i}) {
                left: calc(var(--col-gutter) + #{col-width-vars($i - 1)});
            }
        }
    }

    &__row {
        @include position(absolute, left, 0);
        border-top: 1px solid #ffd700;
        opacity: 0.5;
        width: var(--win-width);

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                top: #{space($i)};
            }
        }
    }
}
