
// CONFETTI!!!!!
///////////////////////////////////////////////////////////
.confetti {
    @include position(fixed, top left right bottom, 0, $z-layer: confetti);
    background-image: url(../images/confetti.svg);
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
}
