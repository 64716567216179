
// PANEL
// (slide-out panel for bid confirmation, etc.)
///////////////////////////////////////////////////////////
.panel {
    @include responsive(--panel-width, (
        default:   calc(#{col-width-vars(4)} + var(--col-gutter) * 2),
        tablet-lg: 50%,
        tablet-sm: calc(#{col-width-vars(8)} + var(--col-gutter) * 2),
        phone: 100%
    ));

    @include position(fixed, top bottom right, 0, $z-layer: panel);
    @extend .background--black;
    /* stylelint-disable indentation */
    box-shadow: rem(-2)  0 rem(1)  transparentize($color-black, 0.94), 
                rem(-4)  0 rem(2)  transparentize($color-black, 0.94), 
                rem(-8)  0 rem(4)  transparentize($color-black, 0.94), 
                rem(-16) 0 rem(8)  transparentize($color-black, 0.94),
                rem(-32) 0 rem(16) transparentize($color-black, 0.94);
    /* stylelint-enable indentation */
    overflow-y: auto;
    padding: var(--headers-height) var(--col-gutter-outer-right) var(--col-gutter) var(--col-gutter-outer-left);
    width: var(--panel-width);
    
    // main top-level containers
    &__close {
        // ignore <button> styles
        --bg: #000;
        --text: #fff;

        $size: rem(18);
        @include position(fixed, top, calc(var(--appheader-height) - #{$size / 2}), right, var(--col-gutter));

        svg {
            height: $size;
            width: $size;

            polygon {
                fill: var(--text);
            }
        }
        &:hover svg,
        &:focus-visible svg {
            polygon {
                fill: $color-bad;
            }
        }
    }

    &__title {
        @extend .text--auction-huge;
        margin-bottom: space(3);

        @include media-query(phone) {
            font-size: rem(48);
            margin-right: col-width(1);
        }
    }

    &__content {
        @include position(relative, $z-index: 2);
        padding-bottom: calc(var(--col-gutter) + #{space(7)});

        &__body {
            @extend .text--body-big;
            margin-bottom: space(2);
            max-width: 20em;
        }
    }

    &__bottom {
        @include position(fixed, right bottom, 0, $z-index: 2);
        background: var(--bg);
        padding: 0 var(--col-gutter-outer-right) var(--col-gutter) var(--col-gutter-outer-left);
        width: var(--panel-width);

        // graident overlay
        &:after {
            @include position(absolute, bottom, 100%, left right, 0);
            background: linear-gradient(to top, $color-black, transparentize($color-black, 0.1) 33%, transparentize($color-black, 1));
            content: '';
            height: space(4);

            @include media-query(phone) {
                height: space(1.5);
            }
        }

        &__above-ctas {
            @extend .text--body-small;
            margin-bottom: space(1);
            max-width: 30em;
        }

        &__ctas {
            @include flex();

            .button:not(:first-child) {
                margin-left: space(1);
            }
        }
    }

    // tabular width for when it's not part of a breakdown
    // (e.g. on Confirm Bid Panel)
    &__tabular {
        @include responsive(width, (
            default: calc(#{col-width-vars(3)} - #{space(1)}),
            mobile: auto
        ));
    }


    // text-y snippets
    &__notes {
        @include flex($wrap: wrap);
        @extend .text--body-small;
        color: $color-grey2;
        margin-top: space(1);

        &__item {
            padding: 0 space(1) 0 0;
            position: relative;
            width: 50%;

            &::after {
                @include position(absolute, top bottom, 0, left, space(-0.5));
                @include borders(left, $color-grey2);
                content: '';
                opacity: 0.4;
            }

            p {
                max-width: 12em;
            }

            &--bold {
                color: $color-bad;

                &::after {
                    @include borders(left, $color-bad);
                }
            }
        }
    }
}
