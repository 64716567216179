
// HEADERS VARIABLES
///////////////////////////////////////////////////////////
$appheader-height: space(2);
$applogo-height: space(3);

$lotheader-height: $appheader-height;
$applogo-height-mobilenav: $appheader-height;
$applogo-fontsize-mobilenav: rem(22);

// shared padding for nav items
$header-item-vert-pad: space(0.5);
$header-item-horiz-pad: space(0.5);

// add new media queries for the point the header will ditch
// the small logo and center-align all nav items
$mediaqueries: map-merge($mediaqueries, (
    appheader-condensed: map-get($mediaqueries, tablet-sm-and-below)
));

// header height isn't explicitly set, but used elsewhere
// where we need to know the cumulative height of the headers
:root {
    --appheader-height: #{$appheader-height};
    
    @include responsive(--headers-height, (
        default: #{$appheader-height + $applogo-height},
        mobilenav: #{$appheader-height}
    ));
}


// HEADERS FUNCTIONS & MIXINS
///////////////////////////////////////////////////////////
// shared styles for the normal header + lot header
@mixin header-common {
    @include position(fixed, top, 0, left, var(--col-gutter-outer-left), right, var(--col-gutter-outer-right));
    @include flex($dir: column, $justify: flex-end);
    @include transition(color, change-color);
    line-height: $font-lineheight-touching;
    white-space: nowrap;

    // text styles
    a {
        position: relative;

        &:hover, &:focus-visible {
            color: $color-grey3;
        }
        &.underlink:hover .underlink__content::after {
            @include borders-set-border-color($color-grey3);
        }
    }

    &--is-white {
        color: $color-white;

        a {
            &.underlink:not(:hover) .underlink__content::after {
                @include borders-set-border-color($color-white);
            }
        }
    }
}

@mixin header-item-padding($include-first: false, $include-last: false) {
    margin-bottom: -1 * $header-item-vert-pad;
    padding: $header-item-vert-pad $header-item-horiz-pad;

    @if $include-first {
        &:first-child {
            margin-left: -1 * $header-item-horiz-pad;
        }
    }
    @if $include-last {
        &:last-child {
            margin-right: -1 * $header-item-horiz-pad;
        }
    }
}


// APPHEADER 
// the main header/nav + mobilenav
///////////////////////////////////////////////////////////
.appheader {
    @include header-common;
    @include z-index(appheader);
    height: $appheader-height;

    // desktop layout
    &__main {
        @include flex($justify: space-between, $align: flex-end);
        width: 100%;

        @include media-query(appheader-condensed) {
            justify-content: center;
        }

        &__logo-small {
            @include hide-on(appheader-condensed);
            flex: 1;
        }

        &__nav {
            @include flex($align: flex-end);
            
            &__divider,
            &__left,
            &__right {
                @include flex();

                a,
                .appheader__divider {
                    @include header-item-padding();
                    @include responsive(margin-left margin-right, (
                        default: $header-item-horiz-pad,
                        appheader-condensed: 0
                    ));
                }
            }

            &__divider {
                flex: 0;
                justify-content: center;
            }

            &__left,
            &__right {
                flex: 1;
            }

            &__left {
                justify-content: flex-end;

                @include media-query(appheader-condensed) {
                    margin-left: -1 * $header-item-horiz-pad;
                }
            }
        }

        &__usernav {
            @include flex($justify: flex-end, $align: flex-end);
            flex: 1;

            @include media-query(appheader-condensed) {
                flex: 0;
            }

            > * {
                @include header-item-padding($include-last: true);
            }

            .appheader__divider--condensed {
                @include show-on(appheader-condensed);
            }
            .appheader__divider--normal {
                @include hide-on(appheader-condensed);
            }

            .dot {
                @include position(absolute, left, 100%);
                transform: translateY(rem(1));
            }
        }

        &__fullname {
            @include hide-on(mobile);
        }
        &__shortname {
            @include show-on(mobile);
        }
    }
        
    &__logo {
        @include flex($justify: center, $align: flex-end);
        height: $applogo-height;
        text-align: center;
        width: 100%;
    }

    &__main,
    &__lot,
    &__logo {
        @include hide-on(mobilenav);
    }


    // mobile header layout
    &__main-mobile {
        @include flex($justify: space-between, $align: flex-end);
        @include show-on(mobilenav, flex);
        @include transition(color, hover);
        display: none;
        height: $appheader-height;
        position: relative;
        width: 100%;
        z-index: 2;

        @at-root .appheader--mobilenav-open #{&} {
            color: $color-white;
        }

        &__hamburger {
            a {
                @include header-item-padding($include-first: true);
            }
        }
        &__usernav {
            a {
                @include header-item-padding($include-last: true);
            }

            .dot {
                @include position(absolute, left, 100%);
                transform: translateX(rem(-2)) translateY(rem(4.5));
            }

            .underlink {
                // when the mobilenav flavor of the app logo is showing,
                // its approximate width is 5.6 * its fontsize;
                $applogo-approx-width: $applogo-fontsize-mobilenav * 5.6;

                &__content {
                    @include text-overflow(ellipsis);
                    max-width: calc((#{col-width-vars(6)}) - #{$applogo-approx-width / 2} - 1.5em);

                    // fix for top and bottom cut off
                    padding: rem(2) 0;
                    transform: translateY(rem(2));
                }
            }
        }
    }


    // mobilenav (overlay)
    &__mobilenav {
        @include position(fixed, top bottom left right, 0);
        @include flex($dir: column, $justify: center);
        @include font(mono, regular, 36, $line-height: 1.2);
        @include hide-on(not-mobilenav);
        @include underlink-set-color($color-white);
        background: $color-black;
        color: $color-white;
        font-size: 10vw;
        overflow: scroll;
        padding-left: var(--col-gutter);
        padding-right: var(--col-gutter);
        padding-top: calc(var(--headers-height) + #{space(2)});

        @include media-query(phone-lg) {
            font-size: rem(40);
        }
    }

    // l'hamburgeur
    &__hamburger {
        // controls how spread out the burger lines are & starting Y pos
        $burger-spacing: rem(3);
        $burger-top: rem(5.2) + $header-item-vert-pad;

        position: relative;

        span {
            padding-left: space(1);
        }
    
        // top, middle, bottom, (+ extra initially hidden line to make X)
        &::before,
        &::after,
        span::before,
        span::after {
            @include position(absolute, left, $header-item-horiz-pad, top, $burger-top + $burger-spacing);
            @include transition(transform, hover);
            content: '—';
            line-height: 0;
        }
        
        // position first + third lines; hide fourth line
        &::before { transform: translateY(-1 * $burger-spacing);}
        span::before { transform: translateY($burger-spacing); }
        span::after { transform: scale(0, 0); }

        // hover (move first + third lines outwards)
        &:hover::before { transform: translateY(-1 * $burger-spacing - rem(1)); }
        &:hover span::before { transform: translateY($burger-spacing + rem(1)); }

        // mobilenav open (turn into an X)
        @at-root .appheader--mobilenav-open #{&} {
            &::before { transform: rotate(45deg) translateX(-50%); }
            &::after { transform: rotate(-45deg) translateX(50%); }
            span::before { transform: rotate(135deg) translateX(50%); }
            span::after { transform: rotate(-135deg) translateX(-50%); }
        }
    }
}


// APPLOGO (it's the logo)
///////////////////////////////////////////////////////////
.applogo {
    @include header-common;
    @include z-index(applogo);
    height: $applogo-height;
    top: $appheader-height;

    @include media-query(mobilenav) {
        @include z-index(applogo-mobilenav);
        height: $applogo-height-mobilenav;
        top: ($applogo-fontsize-mobilenav - 0.95rem) / 2;
    }

    &__logo {
        @include flex($justify: center, $align: flex-end);
        height: $applogo-height-mobilenav;
        text-align: center;
        width: 100%;
    }

    .text--logo {
        @include media-query(mobilenav) {
            font-size: $applogo-fontsize-mobilenav;
        }
    }
    
    @include kern(-0.025em, '24');
    @include kern(-0.01em, Au uc io on);
}


// LOTHEADER
// just the lot header (only appears on scroll)
///////////////////////////////////////////////////////////
.lotheader {
    @include header-common;
    @include hide-on(phone);
    @include z-index(lotheader);
    height: $appheader-height;

    &__auctionnav {
        @include flex($justify: space-between, $align: flex-end);
        width: calc(var(--lot-colmedia-width) - var(--lot-colmedia-rightpad));

        &__logo,
        &__divider {
            @include hide-on(mobile);
            flex: 0;
        }

        &__title {
            $title-descender-offset: 0.3em;
            @include text-overflow(ellipsis);
            padding-bottom: $title-descender-offset;
            padding-top: $title-descender-offset;
            transform: translateY($title-descender-offset);
        }

        &__subnav {
            @include flex();

            a {
                @include header-item-padding();

                &:last-child {
                    @include responsive(margin-left, (full: space(0.5)));
                    margin-right: space(1);
                }
            }
        }

        &__prev::before {
            @include responsive(content, (
                default: '\2190\00A0Previous Lot',
                mobile: '\2190\00A0Prev'
            ));
        }
        &__next::before {
            @include responsive(content, (
                default: 'Next Lot\00A0\2192',
                mobile: 'Next\00A0\2192'
            ));
        }
    }
}

