
// SCROLLBARS
// only for when the browser must show scrollbars
// as determined by Scrollbars.js
///////////////////////////////////////////////////////////
$scrollbar-width: 9px;

:root {
    --scrollbar-width: 0px;
}

@mixin scrollbar-style($bg: $color-black, $thumb: $color-white) {
    scrollbar-color: $bg $thumb;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: var(--scrollbar-width);
        width: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-track {
        background: $bg;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb;
    }
}

// .scrollbars is used for the tester in Scrollbars.js
// and could be used for internal elements that scroll
.test-custom-scrollbars {
    @include scrollbar-style();
    --scrollbar-width: #{$scrollbar-width};
}

// .has-scrollbars is applied to html element (:root)
.has-scrollbars {
    --scrollbar-width: #{$scrollbar-width};

    body,
    .scrollbars {
        @include scrollbar-style();
    }

    .scrollbars--dark {
        @include scrollbar-style($thumb: mix($color-grey3, $color-black, 25%));
    }
} 
