
// BORDER FUNCTIONS & MIXINS
///////////////////////////////////////////////////////////
// chrome now supports 0.5px lines so below shouldn't
// need to be enabled
$chrome-legacy-hairline: false;

:root {
    --border-width: 1px;
}

.app--supports-hairlines {
    --border-width: 0.5px;
}

@mixin hairline-border-image-inset($border-color) {
    border-image-source: svg-encode('<svg width="3px" height="3px" viewBox="0 0 3 3" version="1.1"><path d="M2.5,0.5 L2.5,2.5 L0.5,2.5 L0.5,0.5 L2.5,0.5 Z M2,1 L1,1 L1,2 L2,2 L2,1 Z" fill="#{$border-color}"></path></svg>');
}
@mixin hairline-border-image-outset($border-color) {
    border-image-source: svg-encode('<svg width="3px" height="3px" viewBox="0 0 3 3"><path d="M3,0 L3,3 L0,3 L0,0 L3,0 Z M2.5,0.5 L0.5,0.5 L0.5,2.5 L2.5,2.5 L2.5,0.5 Z" fill="#{$border-color}"></path></svg>');
}
@mixin hairline-border-image-outset-with-bg($border-color, $bg-color) {
    border-image-source: svg-encode('<svg width="3px" height="3px" viewBox="0 0 3 3"><rect fill="#{$bg-color}" x="0" y="0" width="3" height="3"></rect><path d="M3,0 L3,3 L0,3 L0,0 L3,0 Z M2.5,0.5 L0.5,0.5 L0.5,2.5 L2.5,2.5 L2.5,0.5 Z" fill="#{$border-color}"></path></svg>');
}

@mixin borders($sides: all, $border-color: $color-grey1, $inset: false) {
    @if $sides == all {
        border: 1px solid $border-color;
    } @else {
        @each $side in $sides {
            border-#{$side}: 1px solid $border-color;
        }
    }
    
    @at-root .app--supports-hairlines #{&} {
        border-width: 0.5px;
    }

    @if $chrome-legacy-hairline {
        @at-root .app--supports-hairlines.app--chrome #{&} {
            @if $inset {
                @include hairline-border-image-inset($border-color);
            } @else {
                @include hairline-border-image-outset($border-color);
            }
            border-color: transparent;
            border-image-repeat: repeat;
            border-image-slice: 1;
            border-image-width: 1;
            border-width: 1px;
        }
    }
}

@mixin borders-set-border-color($border-color, $disable-hairlines: false) {
    border-color: $border-color;

    @if $disable-hairlines {
        @at-root .app--supports-hairlines #{&} {
            border-width: 1px;
        }
        @if $chrome-legacy-hairline {
            @at-root .app--supports-hairlines.app--chrome #{&} {
                border-color: $border-color;
                border-image: none;
            }
        }
    } @else {
        @if $chrome-legacy-hairline {
            @at-root .app--supports-hairlines.app--chrome #{&} {
                @include hairline-border-image-outset($border-color);
                border-color: transparent;
            }
        }
    }
}

// this mixin is to cover up a 0.5px line of the background color
// when using the chrome hairline fix on an element that's inside
// an element that has an outset hairline border
@mixin chrome-hairline-mask($border-color, $bg-color) {
    @if $chrome-legacy-hairline {
        @at-root .app--supports-hairlines.app--chrome #{&} {
            position: relative;

            &::after {
                @include position(absolute, top bottom left right, -1px);
                border: 1px solid transparent;
                @include hairline-border-image-outset-with-bg($border-color, $bg-color);
                border-image-repeat: repeat;
                border-image-slice: 1;
                border-image-width: 1;
                content: '';
                pointer-events: none;
            }
        }
    }
}
