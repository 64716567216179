
// LOT (the core of the lot page)
///////////////////////////////////////////////////////////
:root {
    @include responsive(--lot-colmedia-width, (
        default: col-width(8),
        tablet-lg: col-width(6),
        tablet-sm-and-below: col-width(12)
    ));

    @include responsive(--lot-colmedia-rightpad, (
        default: var(--col-gutter),
        mobile: 0rem
    ));
}

.lot {
    &__cols {
        @include flex();
        @include media-query(tablet-sm-and-below) {
            flex-wrap: wrap;
        }
    }

    &__col {
        @include flex($dir: column);
    }
    &__col--media {
        margin-top: calc(var(--headers-height) - #{space(0.5)});
        padding-right: var(--lot-colmedia-rightpad);
        width: var(--lot-colmedia-width);

        &.lot__col--has-fullbleed-hero {
            margin-top: 0;
        }
        
        @include media-query(tablet-sm-and-below) {
            order: 2;
        }

        .media {
            height: auto;
            margin-bottom: calc(var(--col-gutter) / 4);
            margin-left: calc(-3 * var(--col-gutter) / 4);
            width: auto;

            &--fullbleed {
                margin-left: calc(-1 * var(--col-gutter));

                .media__media {
                    @include responsive(min-height, (
                        default: 100vh,
                        mobile: 50vw,
                        portrait: 50vw
                    ));
                }
            }

            @include media-query(tablet-sm-and-below) {
                &,
                &--fullbleed {
                    margin-left: calc(-1 * var(--col-gutter-outer-left));
                    margin-right: calc(-1 * var(--col-gutter-outer-right));
                }

                &:last-child {
                    margin-bottom: 0;
                }
                
            }

            &__media {
                width: 100%;
            }
        }
    }
    &__col--info {
        @include z-index(arbitrary);
        flex: 1;
        
        @include media-query(tablet-lg) {
            padding-left: space(2);
        }
        @include media-query(tablet-sm-and-below) {
            order: 1;
        }
    }

    &__hero {
        @include hide-on(tablet-sm-and-below);
    }
    &__mobile-hero {
        @include show-on(tablet-sm-and-below);
        margin-left: calc(-1 * var(--col-gutter-outer-left));
        margin-right: calc(-1 * var(--col-gutter-outer-right));
        width: auto;

        &__media {
            width: 100%;
        }
    }
    
    // titleblock
    &__titleblock {
        @include flex($dir: column, $justify: center);
        min-height: calc(100vh - #{$bidblock-estimated-height});
        padding-bottom: space(2);
        padding-top: calc(var(--headers-height) + #{space(4)});
        
        @include media-query(tablet-sm-and-below) {
            min-height: auto;
            padding-bottom: space(1);
            padding-top: calc(var(--headers-height) + #{space(0.5)});
        }

        @include media-query(full-xl) {
            .fancytitle__line:nth-child(1) {
                margin-left: calc(#{$fancytitle-line1-left-offset} - #{$col-gutter-extra-space-full-xl});
            }
            .fancytitle__line:nth-child(3) {
                margin-left: calc(#{$fancytitle-line3-left-offset} - #{$col-gutter-extra-space-full-xl});
            }
        }
    }
    &__auction {
        @include flex($align: flex-end);
        @include position(relative, $z-layer: above-arbitrary);

        @include media-query(tablet-sm-and-below) {
            @include borders(top);
            align-items: baseline;
            margin-top: space(1);
            padding: space(1) 0;
        }

        &__name {
            margin-right: space(2);
            max-width: 10em;

            &:hover, &:focus-visible {
                color: $color-grey3;
            }
        }

        &__nav {
            flex: 1;
            line-height: 1.5;
            text-align: right;
            white-space: nowrap;
        }

        &__next {
            margin-right: -2ch;
        }
    }
    &__title {
        @include position(relative, $z-layer: arbitrary);
        margin: space(0.5) 0;

        @include media-query(tablet-sm-and-below) {
            @include fancytitle-default-phone-line-offset();
            margin-top: 0;

            .fancytitle {
                margin-top: -0.4em;
            }
        }   
    }
    &__subtitle {
        @include position(relative, $z-layer: arbitrary);
        &__row {
            @include flex($justify: flex-start, $align: baseline);
            margin-bottom: rem(6);

            @include media-query(tablet-lg) {
                flex-direction: column;
            }

            @include media-query(tablet-sm-and-below) {
                margin-bottom: 0;
            }
        }
        &__cell {
            width: 50%;

            @include media-query(tablet-lg) {
                width: 100%;
            }

            &--film-title {
                @include flex($align: baseline);
                padding-right: space(1);

                @include media-query(tablet-lg) {
                    margin-bottom: space(1);
                }
            }

            &--time {
                width: auto;
            }
        }

        &__film-title {
            @extend .text--film-title-med;
            margin-left: 0.25em;

            &:hover, &:focus-visible {
                @extend .text--film-title-hover;
            }
        }

        &__film-title-override {
            @extend .text--film-title-override-med;
            margin-left: 0.25em;
        }

        &__date {
            @extend .text--color-grey3;
            margin-top: rem(6);

            &--open {
                margin-left: 4em;
            }

            .text--spacer {
                @include responsive(display, (
                    full-sm: none,
                    phone: none
                ));
            }
        }
    }

    &__badge {
        @include position(absolute, left, 0, $z-layer: arbitrary);
        @include responsive(width, (
            default: calc(var(--col-width) * 2),
            tablet-lg: calc(var(--col-width) * 2.5),
            tablet-sm: calc(var(--col-width) * 4),
            phone: calc(var(--col-width) * 5)
        ));
        @include responsive(top, (
            default: var(--appheader-height),
            tablet-sm-and-below: calc(var(--headers-height) + #{space(6)})
        ));

        svg {
            transform: translate3d(-12.5%, 0, 0);
        }

        &--mobile-badge {
            display: none;

            svg {
                transform: translate3d(calc(-25% + var(--col-gutter)), 0, 0);
            }
        }

        @include media-query(tablet-sm-and-below) {
            display: none;

            &--mobile-badge {
                display: block;
            }
        }
    }

    // the "moreinfo" area is animated so that you reach the bottom
    // of moreinfo as you scroll to the bottom of the images
    &__moreinfo {
        @include stickyscroller-target($top: space(-2.5), $mq: tablet-lg-and-above);
        @include responsive(padding-top, (
            default: space(4),
            tablet-sm-and-below: 0
        ));
        
        @include media-query(tablet-lg-and-above) {
            .textblock:first-child {
                border-top: none;
                margin-top: 0;
                padding-top: 0;
            }
        }

        @include media-query(tablet-sm-and-below) {
            margin-top: space(1);
        }
    }
    &--status-open &__moreinfo {
        @include stickyscroller-target-change-top($bidblock-estimated-height);
    }

    // bid block
    &--status-open .bidblock {
        @include media-query(tablet-lg-and-above) {
            @include position(sticky, top, 0, $z-layer: bidblock);
        }

        @include media-query(tablet-sm-and-below) {
            @include position(sticky, top, auto, bottom, 0, $z-layer: bidblock);
            order: 100; // puts it at the end
        }
    }

    // textblocks
    .textblock {
        &__body__sidebar,
        .tabular__value {
            @include cols(1, $cols-tablet: 2, $cols-phone: 3);
        }
    }

    // other text styles
    &__time {
        @include responsive(font-size, (
            default: rem(16),
            phone: rem(13)
        ));
    }
}
