
// BOTTOM BAR
// (e.g., GDPR consent)
///////////////////////////////////////////////////////////
.bottombar {
    @extend .background--black;
    @include flex($justify: space-between, $align: center);
    @include position(fixed, left right bottom, 0, $z-layer: bottombar);
    padding: space(1) var(--col-gutter);

    @include media-query(phone) {
        @include flex($dir: column);

        .button {
            width: 100%;
        }
    }

    &__text {
        @extend .text--body;
        margin-right: space(2);
        max-width: 90em;

        @include media-query(phone) {
            margin: 0 0 space(1) 0;
        }
    }
}
