
// GLOBAL TYPE STYLES
///////////////////////////////////////////////////////////
$base-fontsize: rem(12);

$lineheight-tight: 1.2;
$lineheight-roomy: 1.5;

html {
    @include responsive(font-size, (
        default:  100%,
        full-xl:  110%,
        full-xxl: 125%
    ));
}

body,
input,
button,
textarea,
select {
    @extend .background--white;
    @include font(mono, regular);
    @include font-prettifier();
    font-size: $base-fontsize;
    line-height: 1;
}

body {
    background: $color-black;
}

strong,
b {
    @include font-weight(medium);
}

em,
i {
    font-style: italic;
}

@mixin selection-style {
    background: $color-good;
    color: $color-white;
}
::-moz-selection {
    @include selection-style;
}
::selection {
    @include selection-style;
}


// TYPOGRAPHY MIXINS
///////////////////////////////////////////////////////////
@mixin text-overflow($style: ellipsis) {
    overflow: hidden;
    text-overflow: $style;
    white-space: nowrap;
}


// GENERAL TYPE STYLES
///////////////////////////////////////////////////////////
.text {
    // logos
    &--logo {
        @include font(normal, bold, 36);
    }
    &--logo-small {
        @include font(normal, bold, 16);
    }

    // monospaced (rarely needed since default is mono)
    &--mono {
        @include font(mono, regular, 12);
    }
    &--mono-small {
        @include font(mono, regular, 10);
    }
    &--mono-large {
        @include font(mono, regular, 16);
    }

    // auction titles
    &--auction {
        @include font(normal, regular, 16);
    }
    &--auction-med {
        @include font(normal, regular, 27);

        @include media-query(phone) {
            @include font(normal, regular, 20);
        }
    }
    &--auction-large {
        @include font(normal, regular, 46, $line-height: 1);
    }
    &--auction-huge {
        @include font(normal, regular, 56, $line-height: 1);
    }

    // film titles
    &--film-title {
        @include font(lining, regular, 14);
    }
    &--film-title-med {
        @include font(lining, regular, 18, $line-height: $lineheight-tight);
    }
    &--film-title-huge {
        @include font(lining, regular, 56, $line-height: $lineheight-tight);
    }
    &--film-title-hover {
        color: $color-grey3;
    }

    // film title override
    &--film-title-override {
        @include font(normal, regular, 14);
    }

    &--film-title-override-med {
        @include font(normal, regular, 18, $line-height: $lineheight-tight);
    }

    // body text
    @mixin body-shared {
        line-height: 1.3;

        p:not(:last-child) {
            margin-bottom: 0.7em;
        }

        a {
            @include font(lining, regular);
            text-decoration: none;

            &:hover, &:focus-visible {
                color: $color-grey3;
            }
        }
    }
    &--body-small {
        @include body-shared;
        @include font(normal, regular, 12);
    }
    &--body {
        @include body-shared;
        @include font(normal, regular, 14);
    }
    &--body-big {
        @include body-shared;
        @include font(normal, regular, 18);
    }

    // colors
    &--color-grey3 {
        color: $color-grey3;
    }
    &--color-good {
        color: $color-good;
    }
    &--color-bad {
        color: $color-bad;
    }

    // spacer 
    // (for aligning using invisible text, while keeping
    // useful info for screen readers)
    &--spacer {
        color: transparent;
    }
}

@mixin tier-title() {
    @include position(absolute, top, -0.6em, left, 50%);
    background: var(--bg);
    color: $color-grey3;
    max-width: 50%;
    padding: 0 1em;
    text-align: center;
    transform: translateX(-50%);
}

.dot {
    $size: 0.5em;
    background: $color-bad;
    border-radius: $size / 2;
    display: inline-block;
    height: $size;
    margin-left: 0.25em;
    transform: translateY(calc((1em - #{$size}) * -0.3));
    width: $size;
}

// RICH TEXT
///////////////////////////////////////////////////////////
.richtext {
    @extend .text--body-big;

    h1, h2, h3, h4, h5, h6 {
        margin-top: 3em;
    }
    h1 { @include font(normal, regular, 48); }
    h2 { @include font(normal, regular, 36); }
    h3 { @include font(normal, regular, 30); }
    h4 { @include font(normal, regular, 27); }
    h5 { @include font(mono, medium, 18); }
    h6 { @include font(mono, medium, 14); }

    p {
        margin-top: 1em;
    }

    ul,
    ol {
        margin-top: 0.5em;

        li {
            margin-left: 1.1em;
            padding-top: 0.5em;
        }
    }
    ul {
        list-style-type: "— ";
    }
    ol {
        list-style-type: decimal;
    }

    pre {
        @extend .text--mono-small;
        background: $color-grey0;
        border-radius: 0.5em;
        line-height: $lineheight-roomy;
        margin-top: 2em;
        padding: space(0.75) space(1);
        white-space: pre-wrap;
    }

    h1, h2, h3, h4, h5, h6, p, ul, ol, pre {
        &:first-child {
            margin-top: 0;
        }
    }
}

// ANIMATED ELLIPSIS
///////////////////////////////////////////////////////////
.ellipsis {
    @keyframes ellipsis {
        0% { content: '   '; }
        16.66% { content: '.  '; }
        33.33% { content: '.. '; }
        50% { content: '...'; }
        60.66% { content: '.. '; }
        83.33% { content: '.  '; }
        100% { content: '   '; }
    }

    &::after {
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-name: ellipsis;
        content: '  ';
    }
}
