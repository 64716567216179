
// LOT BLOCK: a single lot thumbnail used on landing pages
///////////////////////////////////////////////////////////
$lotblock-toppad: space(2);
:root {
    @include responsive(--lotblock-toppad, (
        default: #{space(2)}
    ));
    @include responsive(--lotblock-botpad, (
        default: #{space(4)},
        phone:   #{space(3)}
    ));
    @include responsive(--lotblock-leftpad --lotblock-rightpad, (
        default:  #{space(3)},
        phone:    #{space(2)},
        phone-sm: #{space(1)}
    ));
}

.lotblock {
    @include z-index(arbitrary);
    display: block;
    padding: var(--lotblock-toppad) var(--lotblock-rightpad) var(--lotblock-botpad) var(--lotblock-leftpad);
    position: relative;

    &:hover, &:focus-visible {
        color: $color-grey3;
    }

    &--only {
        width: 100%;
    }

    &__media {
        @include background-image(contain);

        .media {
            @include media-set-position(center bottom, $fit: contain);

            &__media {
                @include responsive(max-height, (
                    default: space(16),
                    phone: auto
                ));
            }
        }
    }
    &--only &__media {
        .media {
            &__media {
                @include responsive(max-height, (
                    default: space(48),
                    phone: auto
                ));
            }
        }
    }

    &__row {
        @include position(relative, $z-index: 1);
        @include flex();
        margin-top: space(1) - $base-fontsize - rem(1);
        width: 100%;

        &--title {
            min-height: space(1.5);
        }
    }
    &__cell {
        @include flex($align: flex-end);

        &:first-child {
            flex: 1;
        }

        &:last-child {
            flex: 0;
            margin-left: space(1);
            white-space: nowrap;
        }

        &--title {
            position: relative;

            .fancytitle {
                @include position(absolute, left right bottom, 0);
            }

            @include media-query(phone-sm) {
                .fancytitle__line:nth-child(1) {
                    margin-left: calc(-0.6 * (#{col-width-vars(1)}));
                }
                .fancytitle__line:nth-child(3) {
                    margin-left: calc(-0.3 * (#{col-width-vars(1)}));
                }
            }
        }

        &--film {
            align-items: baseline;

            &__title {
                @extend .text--film-title;
                margin-left: 0.25em;
            }

            &__title-override {
                @extend .text--film-title-override;
                margin-left: 0.25em;
            }
        }

        &--bid-status {
            align-items: baseline;
            color: $color-grey3;
            padding-top: rem(2);
        }
    }

    &__badge {
        @include position(absolute, top, calc(0.75 * var(--lotblock-toppad)), right, calc(0.75 * var(--lotblock-rightpad)), $z-index: 1);
        max-width: space(9);
        width: 25%;
    }
}

@mixin lotblock-parent-border() {
    position: relative;

    &::after {
        @include borders(top);
        @include position(absolute, top, 0, left, var(--lotblock-leftpad), right, var(--lotblock-rightpad));
        content: '';
    }
}

@mixin lotblock-parent-no-border() {
    &::after {
        display: none;
    }
}

@mixin lotblock-parent-no-border-for-first-n($n) {
    @for $i from 1 through $n {
        &:nth-child(#{$i})::after {
            display: none;
        }
    }
}

@mixin lotblock-parallax($from-direction, $multiplier: 1) {
    $x: 5vw * $multiplier;
    $y: 5vw * $multiplier;

    .media {
        @if $from-direction == left {
            @include parallax(translateX(calc(var(--progress) * #{$x})));
        } @else if $from-direction == right {
            @include parallax(translateX(calc(var(--progress) * #{-1 * $x})));
        } @else if $from-direction == center {
            @include parallax(translateY(calc(var(--progress) * #{-1 * $y})));
        }
    }
}
