
// COLOR DEFINITIONS
///////////////////////////////////////////////////////////
$color-black: #000;
$color-white: #fff;

$color-grey0: #f0f1f2;
$color-grey1: #d6d9db;
$color-grey2: #959ca3;
$color-grey3: #6f7780;

$color-grey0-neutral: #f1f1f1;

$color-bad: #ff0000;
$color-good: #0000ff;
$color-success: #00ff00; // only used in admin
$color-highlight: #ffff00; // only used in admin

$color-disabled: $color-grey1;
