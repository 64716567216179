
// INFOBOX
// the auction info box, used inside .auctionblock
///////////////////////////////////////////////////////////
.infobox {
    @include borders(all, $color-black);
    @include tabular-set-value(width, $tabular-width-of-longest-date);
    background: $color-white;

    @include media-query(phone) {
        @include tabular-set-value(width, 6rem);

        .date {
            &__timezone {
                display: none;
            }
        }
    }
    
    &__row {
        @include flex();
        &:not(:last-child) {
            @include borders(bottom, $color-black);
        }

        &--top {
            @include media-query(phone) {
                @include tabular-set-value(width, auto);
            }
        }
    }

    &__cell {
        flex: 1;
        padding: space(1) space(2);

        @include media-query(phone) {
            @include multiple-props(padding-left padding-right, space(1.5));
        }

        &:not(:last-child) {
            @include borders(right, $color-black);
        }

        &[class*='infobox__cell--status'] {
            flex-grow: 0;
            position: relative;
        }
        &--status-open {
            @include chrome-hairline-mask($border-color: $color-black, $bg-color: $color-black);
            background: $color-black;
            color: $color-white;
        }
        &--status-soon {
            @include chrome-hairline-mask($border-color: $color-black, $bg-color: $color-grey3);
            background: $color-grey3;
            color: $color-white;
        }
    }

    // text styles
    .text--auction-large {
        display: block;
        padding: space(1) 0;

        @include media-query(phone) {
            padding-bottom: space(0.75);
            padding-top: space(0.5);
        }
    }
}

@mixin infobox-set-colors($bg, $text, $border: null) {
    @include tabular-set-bg-color($bg);
    background: $bg;
    color: $text;

    @if $border {
        &,
        .infobox__row:not(:last-child),
        .infobox__cell:not(:last-child) {
            @include borders-set-border-color($text);
        }
        
        .infobox__cell {
            &--status-open {
                @include chrome-hairline-mask($border-color: $border, $bg-color: $color-black);
            }
            &--status-soon {
                @include chrome-hairline-mask($border-color: $border, $bg-color: $color-grey3);
            }
        }
    }
}
