
// BID TABLE
// table of previous bids on a Lot, as used inside
// a .historyblock on Account Details > Bid History
///////////////////////////////////////////////////////////
.bidtable {
    $bidtable-lineheight: $lineheight-roomy;

    @extend .text--mono-small;
    overflow: hidden;
    position: relative;

    &#{$state-truncated} {
        max-height: 8em * $bidtable-lineheight;
    }

    &__table {
        line-height: $bidtable-lineheight;
        table-layout: fixed;
        width: 100%;
    }

    &__row {
        width: 100%;

        &--hd {
            @include font-weight(medium);
        }
    }

    &__cell {
        box-sizing: content-box;
        text-align: left;
        @include text-overflow(ellipsis);

        &:not(:last-child) {
            padding-right: space(1);
        }
        
        &--time {
            max-width: 15ch;
        }
        &--amt {
            text-align: right;
        }
        &--bidder {
            text-align: right;
            width: 15ch;
        }
    }

    &__show-more {
        @include flex($justify: flex-start, $align: flex-end);
        @include position(absolute, bottom, 0, left right, 0);
        background: linear-gradient(to top, $color-grey0, transparentize($color-grey0, 0.1) 33%, transparentize($color-grey0, 1));
        color: $color-grey2;
        content: '';
        height: 5em * $bidtable-lineheight;

        &:hover, &:focus-visible {
            color: $color-good;
        }
    }
}
