
// EDIT BLOCK
// a section that toggles a form (a la Account Details)
///////////////////////////////////////////////////////////
.editblock {
    padding-bottom: space(2);

    & + & {
        @include borders(top);
        padding-top: space(2);
    }

    &__row {
        @include flex($justify: space-between);
        width: 100%;
    }

    &__cell {
        &--info {
            margin-right: space(2);
        }

        &--cta {
            flex: 0;
        }

        &__hd {
            color: $color-grey3;
            margin-bottom: space(0.5);
        }

        &__bd {
            line-height: $lineheight-roomy;
            word-break: break-word;
        }
    }

    .form {
        padding-bottom: 0;
    }
}
