
// ZERO STATE BLOCK
///////////////////////////////////////////////////////////
.zerostateblock {
    margin-bottom: space(2);
    -webkit-text-fill-color: var(--bg);
    -webkit-text-stroke: 1px mix($color-grey2, $color-grey1, 20%);

    .fancytitle {
        @include media-query(phone) {
            @include fancytitle-default-phone-line-offset();
        }
    }
}
