
// MERCH BLOCK
///////////////////////////////////////////////////////////
.merchblock {
    @include responsive(width, (
        default: 25%,
        tablet-sm-and-below: 50%
    ));
    @include responsive(--merchblock-toppad --merchblock-botpad, (
        default: #{space(2)},
        phone:   #{space(1.5)}
    ));
    @include responsive(--merchblock-leftpad --merchblock-rightpad, (
        default: #{space(2)},
        phone:   var(--col-gutter)
    ));
    
    padding: var(--merchblock-toppad) var(--merchblock-rightpad) var(--merchblock-botpad) var(--merchblock-leftpad);
    position: relative;
    text-align: center;

    &:hover, &:focus-visible {
        color: $color-grey3;
    }

    &__thumb {
        &::before,
        &::after {
            @include position(absolute, top, var(--merchblock-toppad), right, var(--merchblock-rightpad));
            height: space(3);
            width: space(3);
        }
        &::before {
            background: var(--bg);
            content: '';
            z-index: 1;
        }
        &::after {
            background: $color-black;
            clip-path: polygon(0% 0, 100% 100%, 0% 100%);
            color: $color-white;
            content: 'SH\AOP \2197';
            padding: rem(28) 0 0 rem(6);
            text-align: left;
            white-space: pre;
            z-index: 2;
        }
    }
    &:hover &__thumb,
    &:focus-visible &__thumb {
        &::after {
            background: $color-good;
        }
    }

    .media {
        @include borders(all, $color-black, $inset: true);
    }

    h4 {
        @extend .text--body-big;
        margin: space(1) 0 space(0.5);
    }

    &__price {
        color: $color-grey3;
    }

    @include media-query(phone) {
        &:nth-child(odd) {
            padding-right: calc(var(--merchblock-rightpad) / 2);

            .merchblock__thumb {
                &::before,
                &::after {
                    right: calc(var(--merchblock-rightpad) / 2);
                }
            }
        }
        &:nth-child(even) {
            padding-left: calc(var(--merchblock-leftpad) / 2);
        }
    }
}
