
// BID INCREMENTS
// (used as a template tag on How It Works page)
///////////////////////////////////////////////////////////
.bidincrements {
    @extend .text--mono-large;
    @include responsive(font-size, (
        tablet-sm: rem(12),
        phone-sm: rem(11)
    ));
    padding: space(2) 0;
    text-align: right;
    width: 100%;
    
    th,
    td {
        @include borders(bottom);
        @include responsive(padding, (
            default: space(0.25) 0,
            tablet-sm: space(0.2) 0,
            phone-sm: space(0.15) 0
        ));
        white-space: nowrap;

        &:not(:first-child) {
            padding-left: 1ch;
        }

        &:last-child {
            color: $color-good;
            width: 50%;
        }
    }

    th {
        @extend .text--body;
        @include font-weight(bold);
        @include responsive(font-size, (
            phone-sm: rem(11)
        ));
        padding-bottom: space(0.5);

        &:first-child {
            text-align: left;
        }
    }
}
