
// TITLE BLOCK
///////////////////////////////////////////////////////////
.titleblock {
    @include responsive(padding, (
        default: 0 space(2) space(3),
        phone: 0 var(--col-gutter) space(2)
    ));

    &__meta {
        @include flex($justify: space-between, $align: baseline);
        margin-top: space(1);

        @include media-query(phone) {
            flex-direction: column;
            margin-top: space(0.5);
        }
    }

    &__name {
        @extend .text--film-title-med;
        margin-right: space(2);
    }
    &:hover &__name,
    &:focus-visible &__name {
        color: $color-grey3;
    }

    &__status {
        color: $color-grey3;
        white-space: nowrap;

        @include media-query(phone) {
            margin-top: space(0.5);
        }
    }
}
