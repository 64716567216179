.appfooter {
    @include tier-fullbleed($bg: $color-black, $text: $color-white, $z-layer: appfooter);
    @include multiple-props(padding-top padding-bottom, space(6));
    @include underlink-set-color($color-white);

    &__main {
        @include flex($justify: center, $align: flex-start);

        @include tabular-set-label(width, col-width(1));
        @include tabular-set-value(min-width, col-width(1));
        @include tabular-set-bg-color($color-black);

        padding: 0 col-width(2);

        &__nav {
            flex: 2;
        }
        &__logo {
            align-self: center;
            flex: 4;
        }
        &__legalnav {
            flex: 2;
        }

        @include media-query(tablet) {
            @include tabular-set-label(width, col-width(2));
            padding: 0 col-width(1);
        }
        @include media-query(tablet-sm) {
            @include tabular-set-label(width, col-width(3));
            padding: 0;
        }
        @include media-query(phone) {
            @include flex($dir: column, $justify: space-between, $align: flex-start);
            @include tabular-set-label(width, auto);
            @include tabular-set-value(min-width, col-width(5));
            padding: 0;

            &__nav,
            &__logo,
            &__legalnav {
                flex: 1;
                margin-bottom: space(1);
                width: 100%;
            }

            &__logo {
                margin-bottom: space(2);
                order: -1;
            }
        }
    }

    &__img-logo {
        background-image: svg-encode('<svg width="366px" height="152px" viewBox="0 0 366 152" version="1.1"><g fill="#{$color-white}"><path d="M139.2,151.3 C138.1,131.4 146,115.3 162.5,104.9 C172.8,98.5 184.8,94.7 196.4,90.6 C204.9,87.6 206,87.2 206,78 C206,57.1 206,36.3 206,15.4 C206,7.3 204.2,5.4 195.9,5.5 C183.3,5.6 171.3,7.8 160.7,15.1 C157.6,17.3 154.9,20 152.6,22.1 C158.9,21.7 165.3,20 171.3,21 C184.6,23.2 193.1,36.1 191.2,49.3 C189.3,62.8 177.5,72.2 163.6,71.2 C150.8,70.3 140.4,59.1 140.5,45.8 C140.6,29.4 148,16.6 162.7,9.4 C186.7,-2.2 211.1,-1.4 234.5,11 C248.6,18.4 254.9,31.3 254.7,47.2 C254.4,63.2 247.5,75.7 233.1,82.5 C223.5,87.1 212.9,89.5 202.7,92.8 C193.5,95.8 184.2,98.5 175,101.6 C171.4,102.8 168.1,104.9 169.2,109.5 C170.2,114.1 173.9,114.5 177.8,114.5 C200.3,114.5 222.8,114.5 245.3,114.5 C247.4,114.5 249.6,114.5 252.9,114.5 C241.6,127.4 231.1,139.4 220.6,151.3 C193.5,151.3 166.4,151.3 139.2,151.3 Z"></path><path d="M0.5,150.1 C6.7,137.2 12.9,124.2 19,111.3 C27.8,92.6 36.4,73.9 45.2,55 C43.6,51.5 42.1,48.1 40.3,44.1 C59.5,38.5 59,18.8 68.7,5.7 C72.6,14.1 76.4,22.3 80.2,30.5 C98.2,69.4 116.2,108.3 134.2,147.3 C134.8,148.6 135.1,149.9 135.5,151.3 C120.1,151.3 104.7,151.3 89.3,151.3 C84.6,140.6 79.9,130 75.2,119.3 C56.4,119.3 38.3,119.3 19.5,119.3 C14.8,129.9 10.1,140.6 5.4,151.3 C3.7,150.9 2.1,150.5 0.5,150.1 Z M47.4,59.7 C38.6,78.6 30.4,96.5 21.9,114.7 C39.2,114.7 55.6,114.7 72.8,114.7 C64.4,96.4 56.2,78.7 47.4,59.7 Z"></path><path d="M313.2,151.3 C313.2,139.4 313.2,127.4 313.2,114.9 C293.5,114.9 274.2,114.9 252.8,114.9 C287.3,76.2 320.6,38.9 354.8,0.6 C354.8,37.7 354.8,73.6 354.8,109.9 C358.8,109.9 362.2,109.9 365.5,109.9 C365.5,111.5 365.5,113.1 365.5,114.8 C362.2,114.8 358.8,114.8 354.5,114.8 C354.5,127.3 354.5,139.3 354.5,151.3 C340.8,151.3 327,151.3 313.2,151.3 Z M312.8,110.1 C312.8,91.5 312.8,73.3 312.8,53.7 C295.7,72.8 279.4,91.1 262.5,110.1 C280.1,110.1 296.3,110.1 312.8,110.1 Z"></path></g></svg>');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        color: transparent;
        display: block;
        min-height: rem(24);
        width: 100%;

        @include media-query(phone) {
            background-position: center left;
        }
    }

    .underlink#{$state-active} {
        .underlink__content::after {
            display: block;
        }
    }

    &__privacy-prefs-link {
        color: $color-grey2;
        display: block;
        font-size: 0.75em;
        line-height: 1.225;
        max-width: 11em;
        transform: translateY(0.2em);

        &:hover, &:focus-visible {
            color: $color-white;
            text-decoration: underline;
            text-decoration-thickness: var(--border-width);
        }
    }

    &__copyright {
        color: $color-grey2;
    }
}
