
// BACKGROUNDS
///////////////////////////////////////////////////////////
@mixin background($bg: $color-white, $text: $color-black) {
    --bg: #{$bg};
    --text: #{$text};

    background-color: var(--bg);
    color: var(--text);
}
@mixin background-columns($cols, $line: $color-grey1, $hairline: true) {
    $svg-lines: '';
    $svg-lines-hairline: '';

    @for $i from 1 through $cols - 1 {
        $x: (120 / $cols) * $i;
        $svg-lines: $svg-lines + '<line vector-effect="non-scaling-stroke" stroke="#{$line}" x1="#{$x}" y1="0" x2="#{$x}" y2="1" stroke-width="1" />';
        $svg-lines-hairline: $svg-lines-hairline + '<line vector-effect="non-scaling-stroke" stroke="#{$line}" x1="#{$x}" y1="0" x2="#{$x}" y2="1" stroke-width="0.5" />';
    }
    
    background-image: svg-encode('<svg width="120px" height="1px" viewBox="0 0 120 1" preserveAspectRatio="none">#{unquote($svg-lines)}</svg>');
    background-position: center;
    background-repeat: repeat-y;
    background-size: calc(var(--win-width) - var(--col-gutter) * 2) 1px;
    
    @if $hairline {
        @at-root .app--supports-hairlines #{&} {
            background-image: svg-encode('<svg width="120px" height="1px" viewBox="0 0 120 1" preserveAspectRatio="none">#{unquote($svg-lines-hairline)}</svg>');
        }
    }
}
@mixin unset-background-columns() {
    background-image: none;

    @at-root .app--supports-hairlines #{&} {
        background-image: none;
    }
}

.background {
    &--white {
        @include background();
    }
    &--grey {
        @include background($color-grey0);
    }
    &--black {
        @include background($color-black, $color-white);
    }
    
    &--four-cols {
        @include background();
        @include background-columns(4);
    }
    &--three-cols {
        @include background();
        @include background-columns(3);
    }
    &--two-cols {
        @include background();
        @include background-columns(2);
    }

    &--four-cols-responsive {
        @extend .background--four-cols;

        @include media-query(tablet) {
            @include background-columns(3);
        }
        @include media-query(phone) {
            @include background-columns(2);
        }
    }
    &--three-cols-responsive {
        @extend .background--three-cols;

        @include media-query(tablet) {
            @include background-columns(2);
        }
        @include media-query(phone) {
            @include unset-background-columns();
        }
    }
}
