
// SPLIT TIER
// used for pages where there's a nav on the left half of
// the screen and content on the right (e.g. How It Works,
// Account Details, Checkout)
///////////////////////////////////////////////////////////
$splittier-top-offset: space(-2);

.splittier {
    @include flex($justify: space-between, $align: stretch);

    @include media-query(not-phone) {
        margin-top: $splittier-top-offset;
    }

    @include media-query(phone) {
        flex-direction: column;
    }

    &__col {
        width: 50%;

        @include media-query(phone) {
            width: 100%;
        }

        &--nav {
            @include media-query(not-phone) {
                padding-top: -1 * $splittier-top-offset;
            }

            @include media-query(full) {
                padding-left: col-width(2);
            }

            @include media-query(tablet) {
                width: col-width(4);
            }
        }

        &--content {
            @include borders(left);
            flex: 1;
            padding-bottom: space(6);
            padding-left: col-width(1);
            padding-right: calc(#{col-width-vars(1)} - var(--col-gutter));

            @at-root .background--grey #{&} {
                @include borders-set-border-color($color-white, $disable-hairlines: true);
            }

            @include media-query(not-phone) {
                padding-top: calc(#{-1 * $splittier-top-offset} + var(--sidenav-vert-pad));
            }

            @include media-query(tablet) {
                width: col-width(4);
            }

            @include media-query(phone) {
                border: none;
                margin-top: 0;
                padding-left: 0;
                padding-right: 0;
            }

            .form {
                flex: 1;
                width: 100%;
            }
        }
    }

    &__nav {
        @include stickyscroller-target($top: calc(var(--headers-height) + var(--view-toppad)));

        @include media-query(phone) {
            @include flex($dir: column);
            width: 100%;
        }

        .breakdown {
            margin-bottom: space(2);

            @include media-query(full) {
                &__col--media {
                    @include position(absolute, right, 100%, top bottom, 0);
                    margin: 0;
                    padding-left: space(3);
                    padding-right: space(1);
                    width: col-width(2);
                }
            }

            @include media-query(tablet) {
                flex-direction: column;

                &__col--info {
                    order: 2;
                    width: 100%;
                }

                &__col--media {
                    margin-right: space(2);
                }

                .fancytitle {
                    margin-top: -0.5em;
                }
            }

            @include media-query(phone) {
                @include borders(bottom);
                margin-top: space(-1.5);
                order: 2;
                padding-bottom: space(2);

                @at-root .background--grey #{&} {
                    @include borders-set-border-color($color-white, $disable-hairlines: true);
                }
            }
        }   
    }
}
