
// BUTTON TIER
///////////////////////////////////////////////////////////
.buttontier {
    padding: space(3) 0 space(6);

    &--after-auctiontier {
        margin-top: calc(-1 * var(--auctiontier-botpad));
    }

    .button {
        margin: 0 auto 0;
    }
}
