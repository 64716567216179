
// UNDERLINK
// used to hairline-underline links (only used on links
// set in Mono since we use the "Lining" font family for
// links set in the "normal"/not-mono font)
///////////////////////////////////////////////////////////
.underlink {
    $offset: 0.1em;
    cursor: pointer;

    &__content {
        display: inline-block;
        position: relative;
        
        // draw a bottom border, position it exactly in the center of
        // the text using top and transform, then use margin-top to
        // position just below text regardless of line-height;
        &::after {
            @include borders(bottom, $color-black);
            @include position(absolute, top, 50%, left right, 0);
            content: '';
            margin-top: calc(#{$font-lineheight-touching} * 0.5em + #{$offset});
            transform: translateY(-50%);
        }
    }

    &:hover &__content,
    &:focus-visible &__content,
    &#{$state-active} &__content {
        &::after {
            display: none;
        }
    }

    &#{$state-disabled} {
        pointer-events: none;

        .underlink__content::after {
            display: block;
            margin-top: 0;
        }
    }

    &--no-line-default {
        .underlink__content::after {
            display: none;
        }

        &:hover .underlink__content::after,
        &:focus-visible .underlink__content::after,
        &#{$state-active} .underlink__content::after {
            display: block;
        }
    }
}
@mixin underlink-set-color($color, $selector: unquote('.underlink')) {
    #{$selector} {
        .underlink__content::after {
            @include borders-set-border-color($color);
        }
    }
}
