
// Media
///////////////////////////////////////////////////////////
@mixin media-set-position($position, $fit: null) {
    &__media {
        object-position: $position;

        @if $fit {
            object-fit: $fit;
        }
    }
}

.media {
    @include media-set-position(center center);
    @include multiple-props(width height, 100%);
    position: relative;

    &__media {
        height: 100%;
        width: 100%;
    }
}
