
// LOT BLOCK: a single lot thumbnail used on landing pages
///////////////////////////////////////////////////////////
.lotstier {
    @include borders(top bottom);
    @include responsive(padding, (
        default: space(2) 0,
        tablet: space(2) 0 space(4),
        phone: 0
    ));
    position: relative;

    &--not-full-row {
        justify-content: center;
    }

    &--space-below {
        margin-bottom: var(--auctiontier-botpad);
    }

    &__title {
        @include tier-title();
    }

    &__lots {
        @include background-columns(3);
        @include flex($justify: space-between, $align: flex-end, $wrap: wrap);

        @include media-query(tablet) {
            @include background-columns(2);
        }
        @include media-query(phone) {
            @include unset-background-columns();
        }
    }
    &--only-two &__lots {
        justify-content: center;

        @include media-query(not-phone) {
            @include background-columns(2);
        }
    }
    &--only-one &__lots {
        @include unset-background-columns();
        justify-content: center;
    }

    &__lot {
        @include flex($align: flex-end);
        @include lotblock-parent-border();

        @include media-query(full) {
            @include lotblock-parent-no-border-for-first-n(3);
            width: 33.3%;

            &:nth-child(3n + 1) {
                @include lotblock-parallax(left);
            }
            &:nth-child(3n + 2) {
                @include lotblock-parallax(center);
            }
            &:nth-child(3n + 3) {
                @include lotblock-parallax(right);
            }
        }

        @include media-query(tablet) {
            @include lotblock-parent-no-border-for-first-n(2);
            width: 50%;

            &:nth-child(2n + 1) {
                @include lotblock-parallax(left);
            }
            &:nth-child(2n + 2) {
                @include lotblock-parallax(right);
            }
        }

        @include media-query(phone) {
            @include lotblock-parent-no-border-for-first-n(1);
            width: 100%;

            &:nth-child(1n + 1) {
                @include lotblock-parallax(center, $multiplier: 2);
            }
        }

        .lotblock {
            width: 100%;
        }
        
        /*
        .fancytitle__line {
            &:nth-child(1) {
                margin-left: -1rem;
            }
            &:nth-child(3) {
                margin-left: -0.5rem;
            }
        }
        */
    }
    &--only-one &__lot {
        width: 100%;

        @include media-query(full) {
            --lotblock-leftpad: #{col-width(3)};
            --lotblock-rightpad: #{col-width(3)};
        }

        @include media-query(tablet) {
            --lotblock-leftpad: #{col-width(2)};
            --lotblock-rightpad: #{col-width(2)};
        }
        
        @include media-query(not-phone) {
            @include lotblock-parallax(center, $multiplier: 2);
        }
    }
    &--only-two &__lot {
        @include media-query(full) {
            &:nth-child(3n + 2) {
                @include lotblock-parallax(right);
            }
        }
    }
    
}
