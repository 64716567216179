
// BID BLOCK (on lot page)
///////////////////////////////////////////////////////////
// biding block
:root {
    @include responsive(--bidblock-input-height, (
        default: 4.5rem,
        phone-sm: 3.75rem
    ));
    @include responsive(--bidblock-details-min-height, (
        default: 3rem,
        phone-sm: 2.1rem
    ));
}

$bidblock-tabular-lineheight: 1.2;
$bidblock-margin-bottom: space(2);
$bidblock-estimated-height: calc(#{2rem * $bidblock-tabular-lineheight + $bidblock-margin-bottom} + var(--bidblock-input-height) + var(--bidblock-details-min-height) - 0.12rem);

.bidblock {
    align-content: end;
    background: $color-white;
    display: grid;
    grid-template-columns: [col0] 1fr [col1] auto [col2];
    grid-template-rows: [row0] auto [row1] auto [row2] auto [row3];
    margin-bottom: $bidblock-margin-bottom;
    min-height: calc(#{$bidblock-estimated-height} - #{$bidblock-margin-bottom});
    position: relative;

    @include media-query(tablet-sm-and-below) {
        margin: space(2) calc(-1 * var(--col-gutter-outer-right)) 0 calc(-1 * var(--col-gutter-outer-left));
        padding: 0 var(--col-gutter-outer-right) space(1) var(--col-gutter-outer-left);
    }
    
    // graident overlay
    &:after {
        @include position(absolute, top, 100%, left right, space(-0.5));
        background: linear-gradient(to bottom, $color-white, transparentize($color-white, 0.1) 33%, transparentize($color-white, 1));
        content: '';
        height: space(4);

        @include media-query(tablet-sm-and-below) {
            @include position(null, top, auto, left right, 0, bottom, 100%);
            background: $color-white;
            height: space(0.75);
        }
    }
    &:before {
        @include media-query(tablet-sm-and-below) {
            @include position(absolute, top, #{space(-0.75)}, left right, var(--col-gutter), bottom, 100%, $z-index: 1);
            @include borders(top);
            content: '';
        }
    }

    &__label {
        grid-column: col0 / span col1;
        grid-row: row0 / span row1;
        margin-bottom: -0.25rem;

        &#{$state-disabled} {
            color: $color-disabled;
        }

        .tabular {
            line-height: $bidblock-tabular-lineheight;
        }
    }

    .moneyinput {
        grid-column: col0 / span col1;
        grid-row: row1 / span row2;

        &__control {
            @include multiple-props(border-left border-right border-top, none);
        }
        
        &__currency,
        input {
            @include responsive(font-size, (
                default: rem(36),
                phone-sm: rem(24)
            ));
        }

        &__currency {
            left: 0;
        }

        input {
            height: var(--bidblock-input-height);
            padding-left: 1ch;
            padding-right: 0;
        }

        &__errors {
            @include position(absolute, top, 100%, left right, 0, $z-index: 1);
        }

        &__errors__text {
            @include media-query(phone) {
                padding-left: space(0.75);
                padding-right: space(0.75);
            }
        }
    }

    .button {
        @include responsive(font-size, (
            default: rem(16),
            phone-sm: rem(14)
        ));
        grid-column: col1 / span col2;
        grid-row: row1 / span row2;
        height: var(--bidblock-input-height);
        margin-left: space(0.5);
        min-width: col-width(2);

        @at-root .app--supports-hairlines:not(.app--chrome) #{&} {
            height: var(--bidblock-input-height);
        }

        @include media-query(phone-sm) {
            padding-left: space(1.5);
            padding-right: space(1.5);
        }
    }
    &__fullbutton.button {
        grid-column: col0 / span col2;
        margin-left: 0;
    }

    &__details {
        @include flex();
        grid-column: col0 / span col2;
        grid-row: row2 / span row3;
        min-height: var(--bidblock-details-min-height);
        padding-top: space(1);

        @include media-query(phone-sm) {
            padding-top: space(0.5);
        }

        &__unit {
            @include flex($dir: column, $justify: space-between);
            flex-basis: 25%;
            padding-right: space(0.5);
            position: relative;

            &:not(:first-child)::after {
                @include borders(left);
                @include position(absolute, left, space(-0.5), top bottom, 0);
                content: '';
            }
        }

        &__label {
            @include responsive(font-size, (
                default: rem(10),
                phone-sm: rem(7)
            ));
        }

        &__value {
            @include responsive(font-size, (
                default: rem(16),
                phone-sm: rem(13)
            ));
        }

        &__not-phone {
            @include hide-on(phone);
        }
        &__phone {
            @include show-on(phone);
        }
    }
}
