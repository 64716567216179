
// HISTORY BLOCK
// each item on Bid History and Transaction History
// pages under Account Details
///////////////////////////////////////////////////////////
.historyblock {
    display: block;
    padding-bottom: space(3);

    &:not(:first-child) {
        @include borders(top);
        padding-top: space(3);
    }

    &__row {
        @include flex($justify: space-between);
    }

    &__cell {
        flex: auto;
        line-height: $lineheight-roomy;

        &:not(:last-child) {
            padding-right: space(2);
        }

        &--status {
            @include media-query(full) {
                flex: 0;
                min-width: calc(#{col-width-vars(1)} + var(--col-gutter));
            }
        }

        &__hd {
            @extend .text--color-grey3;
        }
    }
    
    // specific to Bid History
    .lotblock {
        padding: 0 0 space(1) 0;
    }

    &__status {
        padding-bottom: space(1);
    }

    .button {
        margin-bottom: space(1);
        width: 100%;
    }

    // specific to Transactions
    .breakdown {
        margin-bottom: space(1);

        &__hd {
            &:hover, &:focus-visible {
                color: $color-grey3;
            }
        }
    }
}
