
// BREAKPOINTS
///////////////////////////////////////////////////////////
// main three states: full, tablet, phone
$phone-max:   666px;
$tablet-max: 1150px;
$tablet-min: $phone-max  + 1;
$full-min:   $tablet-max + 1;

// full sub-states
$full-sm-max:  1299px;
$full-xl-min:  1550px;
$full-xxl-min: 2000px;

// tablet sub-states
$tablet-lg-min: 800px;
$tablet-sm-max: $tablet-lg-min - 1;

// phone sub-states
$phone-lg-min: 501px;
$phone-sm-max: $phone-lg-min - 1;

$phone-tiny-max: 401px;

// mobile nav (which could be different than phone)
$mobilenav-max: $phone-max;

/* stylelint-disable */
:export {
    MOBILENAV_MAX: $mobilenav-max;
    PHONE_MAX: $phone-max;
    TABLET_MIN: $tablet-min;
    TABLET_MAX: $tablet-max;
    FULL_MIN: $full-min;
}
/* stylelint-enable */


// MEDIA QUERIES
// ("mobile" inclues tablet + phone)
///////////////////////////////////////////////////////////
$mediaqueries: (
    full:      'screen and (min-width: #{$full-min})',
    tablet:    'screen and (max-width: #{$tablet-max}) and (min-width: #{$tablet-min})',
    phone:     'screen and (max-width: #{$phone-max})',
    
    full-sm:   'screen and (min-width: #{$full-min}) and (max-width: #{$full-sm-max})',
    full-xl:   'screen and (min-width: #{$full-xl-min})',
    full-xxl:  'screen and (min-width: #{$full-xxl-min})',

    tablet-lg: 'screen and (max-width: #{$tablet-max}) and (min-width: #{$tablet-lg-min})',
    tablet-sm: 'screen and (max-width: #{$tablet-sm-max}) and (min-width: #{$tablet-min})',

    phone-lg:  'screen and (max-width: #{$phone-max}) and (min-width: #{$phone-lg-min})',
    phone-sm:  'screen and (max-width: #{$phone-sm-max})',
    phone-tiny:  'screen and (max-width: #{$phone-tiny-max})',

    mobile:    'screen and (max-width: #{$tablet-max})',
    not-phone:  'screen and (min-width: #{$tablet-min})',

    mobilenav: 'screen and (max-width: #{$mobilenav-max})',
    not-mobilenav: 'screen and (min-width: #{$mobilenav-max + 1})',

    tablet-sm-and-below:  'screen and (max-width: #{$tablet-sm-max})',
    tablet-lg-and-above:  'screen and (min-width: #{$tablet-lg-min})',

    retina: 'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)',

    portrait: 'screen and (orientation: portrait)',
    full-portrait: 'screen and (min-width: #{$full-min}) and (orientation: portrait)',
    tablet-portrait: 'screen and (max-width: #{$tablet-max}) and (min-width: #{$tablet-min}) and (orientation: portrait)',
    tablet-sm-portrait: 'screen and (max-width: #{$tablet-sm-max}) and (min-width: #{$tablet-min}) and (orientation: portrait)',
    phone-portrait: 'screen and (max-width: #{$phone-max}) and (orientation: portrait)',
    not-phone-portrait: 'screen and (min-width: #{$tablet-min}) and (orientation: portrait)',
    
    ratio-goldenratio-and-below: 'screen and (max-aspect-ratio: 81/50)',
    ratio-fourthirds-and-below: 'screen and (max-aspect-ratio: 4/3)',
    ratio-fourthirds-and-above: 'screen and (min-aspect-ratio: 4/3)',
    not-phone-ratio-fourthirds-and-above: 'screen and (min-width: #{$tablet-min}) and (min-aspect-ratio: 4/3)'
    
);


//  RESPONSIVE FUNCTIONS + MIXINS
///////////////////////////////////////////////////////////
@mixin media-query($bp) {
    $val: map-get($mediaqueries, $bp);
    @media #{$val} {
        @content;
    }
}

// the following mixin can apply values at multiple responsive
// states to mutiple properties. "default" is a keyword for
// the value that isn't inside of a media query. use as such:
//
// @include responsive(margin-left margin-right, (
//     default: 1rem,
//     phone: 0
// ));
@mixin responsive($props, $map) {
    @each $bp, $val in $map {
        @if $bp == default {
            @each $prop in $props {
                #{$prop}: $val;
            }
        } @else {
            @include media-query($bp) {
                @each $prop in $props {
                    #{$prop}: $val;
                }
            }
        }
    }
}

@mixin hide-on($bp) {
    @include media-query($bp) {
        display: none;
    }
}

@mixin show-on($bp, $display: block) {
    display: none;
    
    @include media-query($bp) {
        display: $display;
    }
}
