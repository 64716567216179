$state-active: '.state-active';
$state-active-exact: '.state-active--exact';
$state-selected: '.state-selected';
$state-disabled: '.state-disabled';
$state-focused: '.state-focused';
$state-empty: '.state-empty';
$state-error: '.state-error';
$state-highlighted: '.state-highlighted';
$state-open: '.state-open';
$state-pending: '.state-pending';
$state-truncated: '.state-truncated';
$state-hidden: '.state-hidden';

/* stylelint-disable */
:export {
    ACTIVE: unquote($state-active);
    ACTIVE_EXACT: unquote($state-active-exact);
    SELECTED: unquote($state-selected);
    DISABLED: unquote($state-disabled);
    FOCUSED: unquote($state-focused);
    EMPTY: unquote($state-empty);
    ERROR: unquote($state-error);
    HIGHLIGHTED: unquote($state-highlighted);
    OPEN: unquote($state-open);
    PENDING: unquote($state-pending);
    TRUNCATED: unquote($state-truncated);
    HIDDEN: unquote($state-hidden);
}
/* stylelint-enable */
