
// SIDENAV
///////////////////////////////////////////////////////////
:root {
    @include responsive(--sidenav-vert-pad, (
        default: space(1.5),
        phone: space(1)
    ));
}

.sidenav {
    @include responsive(padding-bottom, (
        default: space(4),
        phone: space(3)
    ));


    &__link {
        @extend .text--mono-large;
        @include borders(top);
        display: block;
        margin-right: col-width(1);
        padding: var(--sidenav-vert-pad) 0;
        position: relative;

        @include media-query(tablet-sm) {
            font-size: rem(13);
        }

        @include media-query(phone) {
            margin-right: 0;
            text-align: center;
        }

        &:last-child {
            @include borders(bottom);
        }

        &#{$state-active} {
            @include borders(bottom);
            color: $color-grey3;
            margin-right: 0;
            pointer-events: none;

            &::after {
                @include position(absolute, top bottom, 0, right, -1px);
                background: var(--bg);
                content: '';
                width: 1px;
            }
        }
        &#{$state-active} + & {
            border-top: none;
        }

        &#{$state-disabled} {
            @include underlink-set-color(transparent, unquote('&'));
            color: $color-grey1;
            pointer-events: none;

            @at-root .background--grey #{&} {
                color: $color-white;
            }
        }

        @at-root .background--grey #{&} {
            @include borders-set-border-color($color-white, $disable-hairlines: true);
        }
    }
}
