
// NOT FOUND BLOCK
///////////////////////////////////////////////////////////
$notfoundblock-x-step: 0.5px;
$notfoundblock-y-step: 0.5px;
$notfoundblock-steps: 400;

.notfoundblock {
    @include position(relative, $z-layer: arbitrary);
    margin-left: col-width(2);

    .fancytitle {
        $shadow: '';
        
        @for $i from 1 through $notfoundblock-steps {
            $x-offset: $notfoundblock-x-step * $i;
            $y-offset: $notfoundblock-y-step * $i;
            $shadow: #{$shadow} #{$x-offset} #{$y-offset} #{mix($color-white, $color-grey1, $i * (100 / $notfoundblock-steps))};
            
            @if $i != $notfoundblock-steps {
                $shadow: #{$shadow} unquote(',');
            }
        }
        
        color: $color-white;
        letter-spacing: -0.07em;
        padding-bottom: $notfoundblock-y-step * $notfoundblock-steps * 0.8;
        text-shadow: $shadow;
    }
}
