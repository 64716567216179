
// BADGE 
// svg badge for titles that's overlaid on auctions & lots
///////////////////////////////////////////////////////////
.badge {
    $self: &;
    overflow: hidden;
    pointer-events: none;

    svg {
        display: block;
        height: auto;
        width: 100%;
    }

    // linked badges
    &--linked svg {
        pointer-events: visibleFill;

        &:hover, &:focus-visible {
            &,
            circle,
            path,
            polygon,
            rect {
                fill: $color-black;
            }
        }
    }
    &--linked #{&}__svg--has-hover-shape {
        // if we've designated a specific hover shape, use that
        // as the pointer-events target instead of the whole svg
        pointer-events: none;

        #{$self}__svg__hover {
            pointer-events: visibleFill;
        }
    }

    // specific badge svg styles
    &__svg--fpuo {
        // for promotional use only

        &__ring,
        &__logo {
            // fill: #cb3034;
        }

        &__ring {
            animation: badge-svg-fpuo 9s linear infinite;
            transform-origin: center center;
    
            @keyframes badge-svg-fpuo {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}