
// FANCY TITLE (scales with FancyTitle.vue)
///////////////////////////////////////////////////////////
$fancytitle-line1-left-offset: -0.675em;
$fancytitle-line2-left-offset: -0.075em; // should line up flush w/ container
$fancytitle-line3-left-offset: -0.375em;

.fancytitle {
    @include font(normal, medium);

    --font-size: #{rem(27)};
    
    display: block;
    font-size: var(--font-size);
    letter-spacing: -0.06em;
    line-height: calc((var(--font-size) * #{$font-lineheight-touching + 0.05}) + 2px);
    text-transform: uppercase;

    &__line {
        display: block;
        
        &:nth-child(1) {
            margin-left: $fancytitle-line1-left-offset;
        }
        &:nth-child(2) {
            margin-left: $fancytitle-line2-left-offset;
        }
        &:nth-child(3) {
            margin-left: $fancytitle-line3-left-offset;
        }
    }
    
    // fix for "Q" decenders overlapping next line
    // stylelint-disable indentation
    [data-ch=c-Q] {
        position: relative;
        z-index: 1;

        &::before {
            @include position(absolute, left, 0);
            clip-path: polygon(44% 96%, 100% 96%, 100% 130%, 44% 130%);
            content: 'Q';
            text-shadow: 1px  1px var(--bg),
                        -1px -1px var(--bg),
                         1px -1px var(--bg),
                        -1px  1px var(--bg);
            z-index: -1;
        }
    }
    
    // kerning:
    // flat touching letters (where a flat righthand side of the
    // first letter abuts a flat leftside side of the second)
    @include kern(-0.045em, HB HD HE HF HH HI HL HM HN HP HR HU
                            IB ID IE IF IH II IL IM IN IP IR IU
                            JB JD JE JF JH JI JL JM JN JP JR JU
                            MB MD ME MF MH MI ML MM MN MP MR MU
                            NB ND NE NF NH NI NL NM NN NP NR NU
                            UB UD UE UF UH UI UL UM UN UP UR UU);
    
    // close-to-flat touching letters
    @include kern(-0.03em,  RB RD RE RF RH RI RK RL RM RN RP RR RU
                            BB BD BE BF BH BI BK BL BM BN BP BR BU);
    
    // when dem curves touch the flat letters
    @include kern(-0.02em,  GB GD GE GF GH GI GK GL GM GN GP GR GU
                            OB OD OE OF OH OI OK OL OM ON OP 'OR' OU
                            DB DD DE DF DH DI DK DL DM DN DP DR DU
                            EB ED EE EF EH EI EK EL EM EN EP ER EU
                            SB SD SE SF SH SI SK SL SM SN SP SR SU);
    @include kern(-0.015em, HS IS JS MS NS RS US
                            HC IC JC MC NC RC UC
                            HQ IQ JQ MQ NQ RQ UQ
                            HO IO JO MO NO RO UO
                            HG IG JG MG NG RG UG);

    // slanty letters
    @include kern(-0.1em,   VA AV YA AY TA AT);
    @include kern(-0.08em,  WA PA);
    @include kern(-0.05em,  DA OA QA AG AO OA AC AQ);
    @include kern(-0.03em,  DV OV VO VG VQ QV VC);
    @include kern(-0.02em,  DA FA AB BA OW WO WG DW WQ QW AR AS MA NA);
    @include kern(0.02em,   EA);
    
    // other
    @include kern(-0.03em,  KC KG KO KQ);
    @include kern(-0.005em, BO QU);
    // stylelint-enable indentation

    // special character spacing
    [data-ch=c-№] + [data-ch] {
        margin-left: 0.05em;
    }
    [data-ch] + [data-ch="c-'"] {
        margin-left: -0.01em;
    }
    [data-ch="c-'"] + [data-ch] {
        margin-left: -0.03em;
    }
}

// this isn't applied to all .fancytitles, because in most places
// .fancytitle is inside something with its own padding
@mixin fancytitle-default-phone-line-offset() {
    .fancytitle__line {
        &:nth-child(1) {
            margin-left: -0.2em;
        }
        &:nth-child(3) {
            margin-left: -0.1em;
        }
    }
}
