
// BREAKDOWN
// (breakdown of lot price details)
///////////////////////////////////////////////////////////
.breakdown {
    @include z-index(arbitrary);
    @include flex($align: flex-end);
    position: relative;
    width: 100%;

    @include media-query(mobile) {
        @include tabular-set-fontsize(10);
    }
    
    // the --info and --media column order is interchangable
    &__col {
        &--info {
            @include responsive(width, (
                default:    calc(#{col-width-vars(3)} - #{space(1)}),
                tablet-lg:  calc(#{col-width-vars(4)} - #{space(1)}),
                tablet-sm:  calc(#{col-width-vars(5)} - #{space(1)}),
                phone:      calc(#{col-width-vars(8)})
            ));
            @include responsive(padding-right, (
                default: space(1),
                phone: 0
            ));
            position: relative;
            z-index: 1;
        }

        &--media {
            flex: 1;
        }

        &:not(:first-child) {
            padding-left: space(1);
        }
    }

    &__hd {
        @include flex($align: flex-end);
        margin-bottom: space(1);
        width: 100%;

        &__title {
            .fancytitle__line {
                &:nth-child(1) {
                    margin-left: -0.6rem;
                }
                &:nth-child(3) {
                    margin-left: -0.3rem;
                }
            }
        }
        &__film {
            margin-left: 1ch;

            @include media-query(phone) {
                font-size: rem(10);
            }
        }
    }

    .media {
        &__media {
            object-position: bottom center;
        }
    }

    // text styles
    &__film-title {
        @extend .text--film-title;

        @include media-query(mobile) {
            display: inline-block;
            font-size: rem(13);
        }
    }
}
